import {
  BasicSwiperTile,
  FeaturedSwiperTile,
  ContributorShowcaseItem,
  PlpProduct,
  MemberHero,
  Product,
  CampaignPromo,
  EditorialBlog,
  CrewRecommendation,
  DefaultPageResponse,
  PdpResponse,
  PlpResponse,
  EntityDetails,
  PlpHeader,
  TopbarConfiguration,
  SearchProperties,
  HomeResponse,
  OrderHistoryResponse,
  BlogHero,
  CheckoutConfiguration,
  GetCartResponse,
  ValidatedCartField,
  ValidatedCartFields,
  CartValidateResponse,
  CartResponse,
  PromoCodeResponse,
  CheckoutCartResponse,
  PurchaseLookupResponse,
  ProductAvailabilityResponse,
  Blockquote,
  AddCartResponse,
  Jwks,
  ImageTextLinkBlock,
  BlogAuthor,
  BlogTag,
  CrewStory,
  HomeHeroSlide,
  CrewListingResponse,
  CrewListing,
  RedeemCreditVoucherDetails,
  ContentContributorShowcase,
  TemplateWidget,
  FaqContent,
  WidgetRegionLink,
  WidgetCategoryLink,
  StaticAvailability,
} from '@/types'
import { format } from 'date-fns'
import { AgentConfigurationResponse, GiftVoucher } from '@big-red-group/storefront-common-checkout'

export const agentConfiguration: AgentConfigurationResponse = {
  partnerCode: 'adrenaline',
  catalogueType: 'ADR',
  primaryRegion: 'gold-coast',
  primaryRegionId: 7,
  handlerKey: 'virgin', // Set it to something to customise the cart behaviour
  allowGiveProductAsGift: true,
  productLayout: 'productGalleryHero',
  baseUrl: '',
  displayCommsPreferencesOnCart: true,
  allowCustomerLogins: false,
  agentId: 3371, // TODO add ADR Agent ID
  isBrgAgent: false,
  currencyCode: 'AUD',
  segmentKey: 'Im1QXrhS8pTeoUHLIHEKiNEHi2ikA5FN',
  isBookingEngine: false,
  paymentOptions: [
    {
      type: 'CARD_WEB',
      main: true,
    },
    {
      type: 'AFTERPAY',
      main: false,
    },
  ],
  agentOAuth2Configuration: {
    clientId: 'experience-oz-acf-client',
    clientSecret: 'WaJsXdYNPa3skXQPTfn3kjhtkv7eMxPC',
    wellKnown: 'https://accounts-test.velocityfrequentflyer.com/auth/realms/velocity/.well-known/openid-configuration',
    metadata: [
      {
        key: 'apiKey',
        value: 'lAi7Ib6yBAEwz8Ki9418dbTS0PxP8lMG',
      },
      {
        key: 'Client-Channel',
        value: 'test',
      },
      {
        key: 'memberUrl',
        value: 'https://staging-api.velocityfrequentflyer.com/loyalty/v2/members/profile',
      },
    ],
  },
}

export const topbarConfiguration: TopbarConfiguration = {
  destinations: [
    {
      name: 'Australia',
      states: [
        {
          name: 'New South Sales',
          url: 'new-south-wales',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
          regions: [
            {
              name: 'Sydney',
              url: 'sydney',
              categories: [
                {
                  id: 3,
                  name: 'Attractions',
                  urlSegment: 'attractions',
                },
                {
                  id: 2,
                  name: 'Tours',
                  urlSegment: 'tours',
                },
              ],
              image: 'https://cdn.experienceoz.com.au/assets/state/1/featuredStateRegions/118692.jpg',
            },
            {
              name: 'Byron Bay',
              url: 'byron-bay',
              categories: [
                {
                  id: 3,
                  name: 'Attractions',
                  urlSegment: 'attractions',
                },
                {
                  id: 2,
                  name: 'Tours',
                  urlSegment: 'tours',
                },
              ],
              image: 'https://cdn.experienceoz.com.au/assets/state/1/featuredStateRegions/118692.jpg',
            },
          ],
        },
        {
          name: 'Queensland',
          url: 'queensland',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
          regions: [
            {
              name: 'Gold Coast',
              url: 'gold-coast',
              categories: [
                {
                  id: 3,
                  name: 'Attractions',
                  urlSegment: 'attractions',
                },
                {
                  id: 2,
                  name: 'Tours',
                  urlSegment: 'tours',
                },
              ],
              image: 'https://cdn.experienceoz.com.au/assets/state/1/featuredStateRegions/118692.jpg',
            },
            {
              name: 'Brisbane',
              url: 'brisbane',
              categories: [
                {
                  id: 3,
                  name: 'Attractions',
                  urlSegment: 'attractions',
                },
                {
                  id: 2,
                  name: 'Tours',
                  urlSegment: 'tours',
                },
              ],
              image: 'https://cdn.experienceoz.com.au/assets/state/1/featuredStateRegions/118692.jpg',
            },
          ],
        },
        {
          name: 'Australian Capital Territory',
          url: 'act',
          image: 'https://cdn.experienceoz.com.au/assets/topbar/states/act.jpg',
          regions: [
            {
              name: 'Canberra',
              url: 'canberra',
              categories: [
                {
                  id: 3,
                  name: 'Attractions',
                  urlSegment: 'attractions',
                },
                {
                  id: 2,
                  name: 'Tours',
                  urlSegment: 'tours',
                },
              ],
              image: 'https://cdn.experienceoz.com.au/assets/state/1/featuredStateRegions/118692.jpg',
            },
          ],
        },
        {
          name: 'Western Australia',
          url: 'western-australia',
          image: 'https://cdn.experienceoz.com.au/assets/topbar/states/western-australia.jpg',
          regions: [
            {
              name: 'Perth',
              url: 'perth',
              categories: [
                {
                  id: 3,
                  name: 'Attractions',
                  urlSegment: 'attractions',
                },
                {
                  id: 2,
                  name: 'Tours',
                  urlSegment: 'tours',
                },
              ],
              image: 'https://cdn.experienceoz.com.au/assets/state/1/featuredStateRegions/118692.jpg',
            },
          ],
        },
      ],
    },
    {
      name: 'New Zealand',
      states: [
        {
          name: 'North Island',
          url: 'north-island',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
          regions: [
            {
              name: 'Auckland',
              url: 'auckland',
              categories: [
                {
                  id: 3,
                  name: 'Attractions',
                  urlSegment: 'attractions',
                },
                {
                  id: 2,
                  name: 'Tours',
                  urlSegment: 'tours',
                },
              ],
              image: 'https://cdn.experienceoz.com.au/assets/state/1/featuredStateRegions/118692.jpg',
            },
          ],
        },
        {
          name: 'South Island',
          url: 'south-island',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
          regions: [
            {
              name: 'Queenstown',
              url: 'queenstown',
              categories: [
                {
                  id: 3,
                  name: 'Attractions',
                  urlSegment: 'attractions',
                },
                {
                  id: 2,
                  name: 'Tours',
                  urlSegment: 'tours',
                },
              ],
              image: 'https://cdn.experienceoz.com.au/assets/state/1/featuredStateRegions/118692.jpg',
            },
          ],
        },
      ],
    },
  ],
  categoryTypes: [
    {
      categoryType: 'EXPERIENCE',
      label: 'Adventures',
      categories: [
        {
          name: 'Flying Adventures',
          url: 'flying-adventures',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Driving Adventures',
          url: 'driving-adventures',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Water Sports',
          url: 'water-sports',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Land Adventures',
          url: 'land-adventures',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Gourmet Adventures',
          url: 'gourmet-adventures',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'New Arrivals',
          url: 'new-arrivals',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Low Footprint Adventures',
          url: 'low-footprint-adventures',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Adventure On Sale',
          url: 'adventure-on-sale',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Weekend Adventures',
          url: 'weekend-adventures',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Extreme Adventures',
          url: 'extreme-adventure',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Summer Adventures',
          url: 'summer-adventures',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Family Adventures',
          url: 'family-adventures',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
      ],
    },
    {
      categoryType: 'RECIPIENT',
      label: 'Recipients',
      categories: [
        {
          name: 'Gifts for Him',
          url: 'gifts-for-him',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Gifts for Her',
          url: 'gifts-for-her',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Gifts For Couples',
          url: 'gifts-for-couples',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Gift Ideas for Kids and Families',
          url: 'gift-ideas-for-kids-and-family',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
      ],
    },
    {
      categoryType: 'OCCASION',
      label: 'Occasions',
      categories: [
        {
          name: 'Birthday Gifts',
          url: 'birthday-gifts',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Wedding & Anniversary Gifts',
          url: 'wedding-gifts-page',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: "Valentine's Day Gifts",
          url: 'valentines-day-gifts',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Mothers Day Gifts',
          url: 'mothers-day-gifts',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Fathers Day Gifts',
          url: 'fathers-day-gifts',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Christmas Gifts',
          url: 'christmas-gifts',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Gifts By Price',
          url: 'gifts-by-price',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
        {
          name: 'Black Friday Cyber Monday',
          url: 'cyber-weekend-deals',
          image: 'https://cdn.experienceoz.com.au/assets/state/1/banner/118745.jpg',
        },
      ],
    },
  ],
  displayableLinks: [
    { label: 'Hot deals', url: '/en/tadventures/hot-deals' },
    {
      label: 'Give a gift',
      subLinks: [
        { label: 'Buy', url: '/en/buy-gift-voucher' },
        { label: 'Redeem', url: '/en/redeem' },
      ],
    },
  ],
  helpfulLinks: [
    { label: 'DWI-P', url: '/en/d-w-i-p' },
    { label: 'MRL', url: '/en/must-reloooise' },
  ],
}

export const defaultPageProperties: DefaultPageResponse = {
  agentConfiguration,
  happyHourEvent: {
    id: 1,
    name: 'some campaign',
    content: 'SUPER ULTRA CAMPAIGN! SAVE SAVE SAVE.',
    startTime: '2023-08-07 00:00',
    endTime: '2025-08-07 00:00',
    discount: 10.55,
  },
  searchProperties: {
    regions: [
      { id: 1, name: 'Woodridge', urlSegment: 'woodridge' },
      { id: 2, name: 'Loganlea', urlSegment: 'loganlea' },
    ],
    categories: [
      { id: 1, name: 'Pubs', urlSegment: 'pubs', categoryType: 'EXPERIENCE' },
      { id: 1, name: 'Attractions', urlSegment: 'attractions', categoryType: 'EXPERIENCE' },
    ],
  },
  topbarConfiguration: topbarConfiguration,
}

export const regions: BasicSwiperTile[] = [
  {
    name: 'Gold Coast',
    urlSegment: 'gold-coast',
    image: 'https://cdn.experienceoz.com.au/assets/regionCatalogueTypeAssociation/12/homeRegionThumb/114261.jpg',
  },
  {
    name: 'Adelaide',
    urlSegment: 'adelaide',
    image: 'https://cdn.experienceoz.com.au/assets/regionCatalogueTypeAssociation/17/homeRegionThumb/114270.jpg',
  },
  {
    name: 'Cairns',
    urlSegment: 'cairns',
    image: 'https://cdn.experienceoz.com.au/assets/regionCatalogueTypeAssociation/8/homeRegionThumb/114267.jpg',
  },
  {
    name: 'Hobart',
    urlSegment: 'hobart',
    image: 'https://cdn.experienceoz.com.au/assets/regionCatalogueTypeAssociation/18/homeRegionThumb/114269.jpg',
  },
  {
    name: 'Melbourne',
    urlSegment: 'melbourne',
    image: 'https://cdn.experienceoz.com.au/assets/regionCatalogueTypeAssociation/20/homeRegionThumb/104398.jpg',
  },
  {
    name: 'Sunshine Coast',
    urlSegment: 'sunshine-coast',
    image: 'https://cdn.experienceoz.com.au/assets/regionCatalogueTypeAssociation/15/homeRegionThumb/114268.jpg',
  },
  {
    name: 'Rotorua',
    urlSegment: 'rotorua',
    image: 'https://cdn.experienceoz.com.au/assets/regionCatalogueTypeAssociation/44/homeRegionThumb/104397.jpg',
  },
  {
    name: 'Sydney',
    urlSegment: 'sydney',
    image: 'https://cdn.experienceoz.com.au/assets/regionCatalogueTypeAssociation/9/homeRegionThumb/133379.jpg',
  },
]

export const accountProducts: Product[] = [
  {
    badges: [],
    id: 1,
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152918.png',
    inclusions: [],
    price: 123,
    fromPrice: 120,
    region: 'Gold Coast',
    canonicalRegionUrlSegment: 'gold-coast',
    supplier: 'Australian Outback Spetacular',
    title: 'Australian Outback Spetacular Heartland Dinner and Show',
    url: 'https://www.experienceoz.com.au/en/gold-coast/australian-outback-spectacular-heartland-show',
  },
  {
    badges: [],
    id: 1234,
    image: 'https://cdn.experienceoz.com.au/assets/product/129/newImage/113793.jpg',
    inclusions: [],
    price: 123,
    fromPrice: 120,
    region: 'Gold Coast',
    canonicalRegionUrlSegment: 'gold-coast',
    supplier: 'Currumbin Wildlife Sanctuary',
    title: 'Currumbin Wildlife Sanctuary Entry Tickets',
    url: 'https://www.experienceoz.com.au/en/gold-coast/australian-outback-spectacular-heartland-show',
  },
]

export const plpProducts: PlpProduct[] = [
  {
    id: 6669,
    name: 'Currumbin Wildlife Sanctuary Tickets',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Currumbin Wildlife Sanctuary',
    region: 'Gold Coast',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 59.95,
    retailPrice: 999.12,
    levy: 5.45,
    rating: 4.7,
    hotDeals: [
      {
        productSessionId: 123,
        message: 'EXCLUSIVE in-park discount vouchers with savings on retail and food and beverage!',
        expiry: 19554,
      },
    ],
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 1, name: 'HOTEL_PICKUP', key: 'hotelPickup', label: 'Hotel Pickup' },
      { id: 1, name: 'MEALS_INCLUDED', key: 'mealsIncluded', label: 'Meals Included' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://cdn.experienceoz.com.au/assets/product/129/newImage/113793.jpg'],
  },
  {
    id: 6665,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    hotDeals: [],
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    rating: 4.7,
    decorators: [],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 6664,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    hotDeals: [],
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    rating: 4.7,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 6663,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    hotDeals: [],
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    rating: 4.7,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 6662,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    hotDeals: [],
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    rating: 4.7,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 6661,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    hotDeals: [],
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    rating: 4.7,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66612,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    hotDeals: [],
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 666199,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    hotDeals: [],
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66611,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    hotDeals: [],
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66412,
    name: 'Cristo redentor tour',
    hotDeals: [],
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66413,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    hotDeals: [],
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66614,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    hotDeals: [],
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
]

export const products: Product[] = [
  {
    badges: [],
    id: 1,
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152918.png',
    inclusions: [],
    price: 123,
    fromPrice: 120,
    region: 'Gold Coast',
    canonicalRegionUrlSegment: 'gold-coast',
    supplier: 'Australian Outback Spetacular',
    title: 'Australian Outback Spetacular Heartland Dinner and Show',
    url: 'https://www.experienceoz.com.au/en/gold-coast/australian-outback-spectacular-heartland-show',
  },
  {
    badges: [],
    id: 2,
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152919.png',
    inclusions: [],
    price: 123,
    fromPrice: 120,
    region: 'Gold Coast',
    canonicalRegionUrlSegment: 'gold-coast',
    supplier: 'Australian Outback Spetacular',
    title: 'Australian Outback Spetacular Heartland Dinner and Show',
    url: 'https://www.experienceoz.com.au/en/gold-coast/australian-outback-spectacular-heartland-show',
  },
  {
    badges: [],
    id: 3,
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152920.png',
    inclusions: [],
    price: 123,
    fromPrice: 120,
    region: 'Gold Coast',
    canonicalRegionUrlSegment: 'gold-coast',
    supplier: 'Australian Outback Spetacular',
    title: 'Australian Outback Spetacular Heartland Dinner and Show',
    url: 'https://www.experienceoz.com.au/en/gold-coast/australian-outback-spectacular-heartland-show',
  },
  {
    badges: [],
    id: 4,
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152921.png',
    inclusions: [],
    price: 123,
    fromPrice: 120,
    region: 'Gold Coast',
    canonicalRegionUrlSegment: 'gold-coast',
    supplier: 'Australian Outback Spetacular',
    title: 'Australian Outback Spetacular Heartland Dinner and Show',
    url: 'https://www.experienceoz.com.au/en/gold-coast/australian-outback-spectacular-heartland-show',
  },
]

export const plpProductsLong: PlpProduct[] = [
  {
    id: 6669,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 23.0,
    retailPrice: 999.12,
    levy: 5.45,
    hotDeals: [],
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 6665,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    hotDeals: [],
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 6664,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    hotDeals: [],
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 6663,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    hotDeals: [],
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 6662,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    hotDeals: [],
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 6661,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    hotDeals: [],
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66913,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    hotDeals: [],
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 666199,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    hotDeals: [],
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66611,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    hotDeals: [],
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66612,
    name: 'Cristo redentor tour',
    hotDeals: [],
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66613,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    operatorName: 'Sergio Cabral',
    region: 'rio',
    hotDeals: [],
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66615,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    hotDeals: [],
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66616,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    hotDeals: [],
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66617,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    hotDeals: [],
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66618,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    hotDeals: [],
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66619,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    hotDeals: [],
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66620,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    hotDeals: [],
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
  {
    id: 66640,
    name: 'Cristo redentor tour',
    urlSegment: 'crist-tour',
    operatorId: 6662,
    hotDeals: [],
    operatorName: 'Sergio Cabral',
    region: 'rio',
    canonicalRegionUrlSegment: 'rio',
    summary: 'Most crowded place in rio',
    isLive: true,
    fromPrice: 666.0,
    retailPrice: 999.0,
    levy: 5.45,
    decorators: [
      { id: 1, name: 'BEST_SELLER', key: 'bestSeller', label: 'Best Seller' },
      { id: 2, name: 'MOST_POPULAR', key: 'mostPopular', label: 'Most Popular' },
    ],
    geolocations: [
      {
        label: 'Cristo redentor',
        address: 'Alto da Boa Vista, Rio de Janeiro - State of Rio de Janeiro, Brazil',
        latitude: 22.9068,
        longitude: 43.1729,
      },
    ],
    images: ['https://a.cdn-hotels.com/gdcs/production143/d357/42fb6908-dcd5-4edb-9f8c-76208494af80.jpg'],
  },
]

export const heroSlides: HomeHeroSlide[] = [
  {
    cta: 'Shop Fathers Day sales',
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/157937.jpg',
    link: 'https://google.com',
    heading: 'Get Dad an adventure this year',
  },
  {
    cta: 'View driving experiences',
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/157936.jpg',
    link: 'https://google.com',
    heading: 'Set your heart racing',
  },
]

export const contributorShowcaseItems: ContributorShowcaseItem[] = [
  {
    showcaseHeading: 'Emma Hanney',
    showcaseImageUrl: 'https://cdn.experienceoz.com.au/assets/contentContributor/6/image/163990.png',
    contributorImage: 'https://cdn.experienceoz.com.au/assets/contentContributor/6/image/163990.png',
    products: [
      {
        name: 'Tandem Skydive Over Great Ocean Road - 12,000ft',
        supplierName: '1300 Skydive - Torquay',
        urlPath: '/great-ocean-road/tandem-skydive-over-great-ocean-road-weekend-12-000ft',
        fromPrice: 299,
        retailPrice: 299,
        imageUrl: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/21355/image/337317.jpg',
        decorators: [
          {
            id: 1,
            name: 'BEST_SELLER',
            key: 'bestSeller',
            label: 'Best seller',
          },
          {
            id: 17,
            name: 'HIGH_ADRENALINE_LEVEL',
            key: 'highAdrenalineLevel',
            label: 'Intensity level high',
          },
        ],
        subcategoryUrlSegments: ['extreme-adventure', 'flying-adventures'],
        categoryUrlSegments: ['skydiving'],
      },
      {
        name: 'Ningaloo Reef Swim with Whale Sharks, Full Day',
        supplierName: 'Coral Bay Eco Tours',
        urlPath: '/exmouth/swimming-with-whale-sharks-coral-bay',
        imageUrl: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/31664/image/314854.jpg',
        fromPrice: 564,
        retailPrice: 564,
        decorators: [
          {
            id: 16,
            name: 'MEDIUM_ADRENALINE_LEVEL',
            key: 'mediumAdrenalineLevel',
            label: 'Intensity level moderate',
          },
        ],
        subcategoryUrlSegments: ['cruises-11', 'water-sports'],
        categoryUrlSegments: ['cruises', 'snorkelling', 'swim-with-whales'],
      },
      {
        name: 'Ningaloo Reef Swim with Manta Rays, Full Day Snorkel Tour',
        supplierName: 'Coral Bay Eco Tours',
        urlPath: '/exmouth/ningaloo-reef-snorkel-swim-with-manta-rays',
        fromPrice: 325,
        retailPrice: 325,
        imageUrl: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/31661/image/314864.jpg',
        decorators: [
          {
            id: 16,
            name: 'MEDIUM_ADRENALINE_LEVEL',
            key: 'mediumAdrenalineLevel',
            label: 'Intensity level moderate',
          },
        ],
        subcategoryUrlSegments: ['water-sports'],
        categoryUrlSegments: ['cruises', 'snorkelling', 'swim-with-whales'],
      },
    ],
  },
  {
    showcaseHeading: 'Ken Kawaura',
    showcaseImageUrl: 'https://cdn.experienceoz.com.au/assets/contentContributor/2/image/163986.png',
    contributorImage: 'https://cdn.experienceoz.com.au/assets/contentContributor/2/image/163986.png',
    products: [
      {
        name: 'Sydney Extreme Aerobatics Experience, 45 Minutes',
        supplierName: 'Australian Aerobatic Academy',
        urlPath: '/sydney/extreme-aerobatics-experience-45-minutes-sydney',
        fromPrice: 699,
        retailPrice: 699,
        imageUrl: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/33386/image/337409.jpg',
        subcategoryUrlSegments: ['flying-adventures'],
        categoryUrlSegments: ['aerobatic-flights'],
      },
      {
        name: 'Olympic Park & Sydney Harbour Private Flight, 30 Minutes - For up to 3',
        supplierName: 'Helicopter Transport & Training Pty Ltd',
        urlPath: '/sydney/private-helicopter-flight-30-minutes-olympic-park-sydney',
        fromPrice: 610,
        retailPrice: 610,
        imageUrl: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/33157/image/338370.jpg',
        subcategoryUrlSegments: ['flying-adventures'],
        categoryUrlSegments: ['helicopter-flights'],
      },
      {
        name: 'Sydney Harbour Jet Boat Ride, 30 Minutes',
        supplierName: 'Oz Jetboating Sydney Harbour',
        urlPath: '/sydney/the-shark-attack-thrill-ride',
        fromPrice: 71.2,
        retailPrice: 89,
        levy: 0,
        rating: 4.9,
        imageUrl: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/20706/image/209005.jpg',
        decorators: [
          {
            id: 1,
            name: 'BEST_SELLER',
            key: 'bestSeller',
            label: 'Best seller',
          },
          {
            id: 17,
            name: 'HIGH_ADRENALINE_LEVEL',
            key: 'highAdrenalineLevel',
            label: 'Intensity level high',
          },
        ],
        subcategoryUrlSegments: ['water-sports'],
        categoryUrlSegments: ['jet-boat-rides'],
      },
    ],
  },
  {
    showcaseHeading: 'Max Homer',
    showcaseImageUrl: 'https://cdn.experienceoz.com.au/assets/contentContributor/3/image/163987.png',
    contributorImage: 'https://cdn.experienceoz.com.au/assets/contentContributor/3/image/163987.png',
    products: [
      {
        name: 'Tandem Skydiving Over the Beach, Up to 12,000ft - Gold Coast',
        supplierName: 'Gold Coast Skydive',
        urlPath: '/gold-coast/tandem-skydive-tandem-skydiving',
        fromPrice: 425,
        retailPrice: 425,
        levy: 0,
        imageUrl: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/33000/image/206979.jpg',
        decorators: [
          {
            id: 13,
            name: 'MEDIUM_FITNESS_LEVEL',
            key: 'mediumFitnessLevel',
            label: 'Fitness level moderate',
          },
          {
            id: 16,
            name: 'MEDIUM_ADRENALINE_LEVEL',
            key: 'mediumAdrenalineLevel',
            label: 'Intensity level moderate',
          },
        ],
        subcategoryUrlSegments: ['activities', 'flying-adventures'],
        categoryUrlSegments: ['skydiving'],
      },
      {
        name: 'Brisbane City Scenic Helicopter Flight, 20 Minutes',
        supplierName: 'V2 Helicopters Flight Training Pty. Ltd.',
        urlPath: '/brisbane/brisbane-cbd-scenic-flight',
        fromPrice: 389,
        retailPrice: 389,
        imageUrl: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/40538/image/397231.jpg',
        subcategoryUrlSegments: ['flying-adventures'],
        categoryUrlSegments: ['helicopter-flights', 'scenic-flights'],
      },
      {
        name: 'Hot Air Balloon Flight with Breakfast and Photos - Gold Coast',
        supplierName: 'Go Ballooning Gold Coast',
        urlPath: '/gold-coast/go-ballooning-hot-air-balloon-flight',
        fromPrice: 349,
        retailPrice: 349,
        levy: 0,
        rating: 4.3,
        imageUrl: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/21031/image/534425.jpg',
        decorators: [
          {
            id: 17,
            name: 'HIGH_ADRENALINE_LEVEL',
            key: 'highAdrenalineLevel',
            label: 'Intensity level high',
          },
        ],
        subcategoryUrlSegments: ['flying-adventures'],
        categoryUrlSegments: ['hot-air-ballooning'],
      },
    ],
  },
  {
    showcaseHeading: 'Toni Westlake',
    showcaseImageUrl: 'https://cdn.experienceoz.com.au/assets/contentContributor/9/image/164000.png',
    contributorImage: 'https://cdn.experienceoz.com.au/assets/contentContributor/9/image/164000.png',
    products: [
      {
        name: 'Sydney V8 Drive & Passenger Lap Combo, 8 Laps',
        supplierName: 'Fastrack V8 Experiences - Sydney',
        urlPath: '/sydney/the-chicane-6-drive-laps-and-2-ride-laps',
        fromPrice: 640,
        retailPrice: 640,
        imageUrl: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/21186/image/396303.jpg',
        decorators: [
          {
            id: 1,
            name: 'BEST_SELLER',
            key: 'bestSeller',
            label: 'Best seller',
          },
          {
            id: 17,
            name: 'HIGH_ADRENALINE_LEVEL',
            key: 'highAdrenalineLevel',
            label: 'Intensity level high',
          },
        ],
        subcategoryUrlSegments: ['driving-adventures', 'gifts-for-him'],
        categoryUrlSegments: ['gift-ideas-for-boyfriends', 'gift-ideas-for-fathers', 'v8-experiences', 'v8-hot-laps'],
      },
      {
        name: 'Blue Mountains Canyoning and Abseiling - Full Day ',
        supplierName: 'Eagle Rock Adventures',
        urlPath: '/blue-mountains/canyoning-and-abseiling-full-day',
        fromPrice: 299,
        retailPrice: 299,
        imageUrl: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/27690/image/274489.jpg',
        decorators: [
          {
            id: 16,
            name: 'MEDIUM_ADRENALINE_LEVEL',
            key: 'mediumAdrenalineLevel',
            label: 'Intensity level moderate',
          },
        ],
        subcategoryUrlSegments: ['land-adventures'],
        categoryUrlSegments: ['abseiling', 'canyoning'],
      },
      {
        name: 'Newcastle Beach Tandem Skydive up to 15,000ft - Weekend ',
        supplierName: 'Skydive Australia - Skydive Newcastle',
        urlPath: '/newcastle/tandem-skydive-up-to-15-000ft-newcastle-weekend',
        fromPrice: 399,
        retailPrice: 399,
        imageUrl: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/33098/image/269446.jpg',
        decorators: [
          {
            id: 17,
            name: 'HIGH_ADRENALINE_LEVEL',
            key: 'highAdrenalineLevel',
            label: 'Intensity level high',
          },
        ],
        subcategoryUrlSegments: ['flying-adventures'],
        categoryUrlSegments: ['skydiving'],
      },
    ],
  },
]

export const contentContributorShowcase: TemplateWidget = {
  name: 'content-contributor-showcase',
  title: 'Crew Members',
  contributors: contributorShowcaseItems,
}

export const memberHero: MemberHero = {
  image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152817.jpg',
  location: '',
  title: 'Join the crew',
}

export const campaignPromo: CampaignPromo = {
  title: '<span>Make a</span><span>break for it</span>',
  buttonLabel: 'Shop gift vouchers',
  url: '/gift-vouchers',
  variant: 'video',
  media: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/video/62862.webm',
}

export const campaignPromoTwo: CampaignPromo = {
  title: '<span>F*ck cake</span><span>give adventure</span>',
  buttonLabel: 'Shop gift vouchers',
  url: '/gift-vouchers',
  variant: 'edgy',
  media: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152994.jpg',
}

export const recommendedForYou: BasicSwiperTile[] = [
  {
    name: "Father's day",
    urlSegment: 'en/fathers-day',
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152994.jpg',
  },
  {
    name: 'Near me',
    urlSegment: 'en/near-me',
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152995.jpg',
  },
  {
    name: 'Specials',
    urlSegment: 'en/specials',
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152996.jpg',
  },
  {
    name: 'New arrivals',
    urlSegment: 'en/new-arrivals',
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152997.jpg',
  },
  {
    name: 'Christmas ideas',
    urlSegment: 'en/christmas-ideas',
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152998.jpg',
  },
]

export const featuredCategories: FeaturedSwiperTile[] = [
  {
    name: 'Kiteboarding',
    link: '/en/kiteboarding',
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152994.jpg',
  },
  {
    name: 'Rally driving',
    link: '/en/rally-driving',
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152994.jpg',
  },
  {
    name: 'Sand safari',
    link: '/en/sand-safari',
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152994.jpg',
  },
  {
    name: 'Scenic flights',
    link: '/en/flights',
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152994.jpg',
  },
  {
    name: 'Swimming with sharks',
    link: '/en/shark-diving',
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152994.jpg',
  },
]

export const blogs: EditorialBlog[] = [
  {
    image: 'https://cdn.experienceoz.com.au/assets/regionCatalogueTypeAssociation/17/homeRegionThumb/114270.jpg',
    title: 'Aute labore elit magna fugiat ullamco tempor reprehenderit occaecat dolor dolor.',
    link: 'foo1',
  },
  {
    image: 'https://cdn.experienceoz.com.au/assets/regionCatalogueTypeAssociation/17/homeRegionThumb/114270.jpg',
    title: 'Aute labore elit magna fugiat ullamco tempor reprehenderit occaecat dolor dolor.',
    link: 'foo2',
  },
  {
    image: 'https://cdn.experienceoz.com.au/assets/regionCatalogueTypeAssociation/17/homeRegionThumb/114270.jpg',
    title: 'Aute labore elit magna fugiat ullamco tempor reprehenderit occaecat dolor dolor.',
    link: 'foo3',
  },
]

export const crewRecommendations: CrewRecommendation = {
  avatarThumbnail: 'https://cdn.experienceoz.com.au/assets/product/129/newImage/113793.jpg',
  avatarPortrait: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152998.jpg',
  title: "Crew member Tanya's greatest adventures",
  products: products,
}

export const staticAvailability: StaticAvailability = {
  staticAvailability: {
    certificates: [
      {
        id: 169704,
        reference: '4-2824-9928-3671',
        optionId: 104187,
        balance: 7000,
        faceValue: 7000,
        paidValue: 7000,
        pgp: '2026-08-11',
        pgpPeriod: 'P18M',
        expiry: '2030-02-11',
      },
    ],
    sessions: [
      {
        id: 14099,
        name: 'General Admission',
        isExtra: false,
        options: [
          {
            id: 104187,
            retailPrice: 7000,
            price: 6300,
            type: 'ADULT',
            certificateMatches: [
              {
                certificateId: 169704,
                amount: 6300,
                type: 'ADULT',
                matchType: 'ID',
              },
            ],
          },
          {
            id: 104188,
            retailPrice: 5000,
            price: 4500,
            type: 'CHILD_3_13',
          },
          {
            id: 104189,
            retailPrice: 5500,
            price: 4950,
            type: 'CONCESSION_PE_SE',
          },
          {
            id: 104190,
            retailPrice: 21500,
            price: 19350,
            type: 'FAMILY_2A_2C',
          },
        ],
      },
    ],
  },
}

export const productResponse: PdpResponse = {
  error: false,
  ...defaultPageProperties,
  entityType: 'PRODUCT',
  productDetails: {
    id: 129,
    urlSegment: 'currumbin-wildlife-sanctuary-general-admission',
    name: 'Currumbin Wildlife Sanctuary Tickets',
    operatorName: 'Currumbin Wildlife Sanctuary',
    operatorId: 53,
    operatorPhone: '+61 7 55340879',
    allowsExperienceVoucherPurchase: true,
    canonicalRegionUrlSegment: 'gold-coast',
    bookingRequired: false,
    title: 'Currumbin Wildlife Sanctuary Ticket Deals | Experience Oz',
    summary:
      "Buy tickets to the Gold Coast's popular Currumbin Wildlife Sanctuary attraction and receive EXCLUSIVE in-park discount vouchers!",
    description:
      "Currumbin Wildlife Sanctuary on the Gold Coast has long been one of the most popular attractions in south-east Queensland, and it isn't hard to see why. With great prices on tickets for the huge array of things to do, Currumbin Sanctuary offers one of the best value days out for families and individuals alike. Get up close with a ton of the best and cutest native Aussie and exotic wildlife at the many interactive displays and wide-open enclosures, while walking amongst the lush, comfortable eucalyptus and rainforest environments. All your animal favourites are here; you can cuddle up to and have your photo taken with a koala, get up close with crocodiles, hand-feed the many friendly kangaroos and much, much more. \n\nBe sure to stay around for the Rainbow Lorikeet feeding - one of the main attractions - with two demonstrations, morning from 8am and afternoon from 4pm. Buy your Currumbin Wildlife Sanctuary tickets here to visit this ever-popular zoological facility that is located on the Gold Coast Highway at Currumbin, around 5 minutes from Gold Coast Airport and approximately 20 mins from Surfers Paradise. Open all year round (except Christmas and ANZAC Day), pay a visit to Currumbin Wildlife Sanctuary today - you won't be disappointed.",
    moreInformation: [
      {
        heading: 'Inclusions',
        content:
          '* Currumbin Wildlife Sanctuary General Admission\n* 10% off food and beverage EXCLUSIVE discount voucher\n* 10% off retail purchases EXCLUSIVE discount voucher',
      },
      {
        heading: 'Exclusions',
        content: '* Food and beverages\n* Souvenirs',
      },
      {
        heading: 'Highlights',
        content:
          '* Escape and explore this iconic Gold Coast wildlife sanctuary\n* Native Australian wildlife\n* Koalas, Kangaroos, Crocodiles, Snakes, Lorikeets, Wombats, Tasmanian Devils, Dingoes and so much more\n* Daily shows and presentations\n* Follow the trail in the Lost Valley game and snap photos of awesome alligators, slithering snakes and dangerous dinosaurs',
      },
      {
        heading: 'Time',
        content:
          '* Open daily from 9am - 4pm \n* Closed Christmas Day 25 December and Anzac Day 25 April\n* Operating hours are subject to change without notice\n* To make the most of your visit, we recommend allowing at least 4 hours to walk through the attraction',
      },
      {
        heading: 'Timetable',
        content:
          "* 800       Wild Lorikeet Feeding\n* 10:00       Pelican & Eel Show\n* 10:15       Blinky Bill's Rookie Ranger Station\n* 1045       Wild Skies Free Flight Bird Show\n* 11:15       Wildlife Hospital Presentation\n* 11:15       Blinky Bill's Rookie Ranger Station\n* 11:45       Aussie Sales and Tails\n* 12:30       Blinky Bill's Rookie Ranger Station\n*14:15       Wild Skies Free Flight Bird Show\n* 15:00       Aboriginal Culture Show\n* 16:00       Wild Lorikeet Feeding",
      },
      {
        heading: 'Location',
        content:
          '* 28 Tomewin Street, Currumbin QLD. 25 minutes south of Surfers Paradise and 10 minutes north of Coolangatta. 5 minutes north of Gold Coast Airport. \n* View the [Currumbin Sanctuary map](https://cdn.experienceoz.com.au/assets/catalogueType/2/miscPDF/104322.pdf)',
      },
      {
        heading: 'Additional Information',
        content:
          "* Tickets are non-refundable\n* Please be ready to make on-site payments using a 'contactless' bank card, rather than cash\n* Currumbin Wildlife Sanctuary politely requests that you refrain from visiting should you start displaying any of the symptoms associated with COVID-19",
      },
      {
        heading: 'Booking Information',
        content:
          '* Savings based on front gate prices\n* Latecomers may not be allowed entry\n* Confirmation in the form of a tour voucher will be received at time of booking\n* Mobile Vouchers are accepted! - Vouchers can also be presented on your mobile device for this attraction\n* For your information, complete Tour Operator Information including contact details is included on your tour voucher',
      },
    ],
    helpCode: 'D2B-81',
    levy: 0,
    fromPrice: 54.95,
    highlights: [
      'Cheapest entry tickets online',
      "The Gold Coast's best wildlife park",
      'Visit a genuine working wildlife hospital',
      '10% off food and beverages in-park',
      '10% off retail purchases in-park',
      'Feed and interact with Kangaroos',
    ],
    productSessions: [
      {
        id: 14099,
        hotDeal: { message: 'Some message', expiry: 0 },
        sessionName: 'General Admission',
        evSession: true,
        productOptions: [
          {
            id: 104187,
            name: 'Adult',
            price: 54.95,
            retailPrice: 54.95,
            type: 'ADULT',
            maxQty: 20,
            minQty: 1,
            defaultQty: 0,
            multiple: 1,
            weight: 1,
            isFromPrice: true,
          },
          {
            id: 104188,
            name: 'Child (3-13yrs)',
            price: 41.95,
            retailPrice: 41.95,
            type: 'CHILD_3_13',
            maxQty: 20,
            minQty: 1,
            defaultQty: 0,
            multiple: 1,
            weight: 1,
            isFromPrice: false,
          },
          {
            id: 104189,
            name: 'Concession (Pen/Sen)',
            price: 41.95,
            retailPrice: 41.95,
            type: 'CONCESSION_PE_SE',
            maxQty: 20,
            minQty: 1,
            defaultQty: 0,
            multiple: 1,
            weight: 1,
            isFromPrice: false,
          },
          {
            id: 104190,
            name: 'Family (2A+2C)',
            price: 169.95,
            retailPrice: 169.95,
            type: 'FAMILY_2A_2C',
            maxQty: 20,
            minQty: 1,
            defaultQty: 0,
            multiple: 1,
            weight: 1,
            isFromPrice: false,
          },
        ],
        tourHour: 10,
        tourMinute: 0,
        tourDuration: 360,
        hasPickups: 'none',
        levy: 0,
        isExtra: false,
      },
    ],
    geolocations: [
      {
        latitude: -28.136029,
        longitude: 153.488742,
        address: '28 Tomewin St, Currumbin, QLD 4223',
        label: 'Currumbin Wildlife Sanctuary',
      },
    ],
    images: [
      'https://cdn.experienceoz.com.au/assets/product/129/newImage/113793.jpg',
      'https://cdn.experienceoz.com.au/assets/product/129/newImage/113791.jpg',
      'https://cdn.experienceoz.com.au/assets/product/129/newImage/113792.jpg',
      'https://cdn.experienceoz.com.au/assets/product/129/newImage/137346.jpg',
      'https://cdn.experienceoz.com.au/assets/product/129/newImage/137381.jpg',
      'https://cdn.experienceoz.com.au/assets/product/129/newImage/137382.jpg',
    ],
  },
  productRecommendations: [
    {
      productId: 514,
      operatorName: 'Tropical Fruit World Tours',
      productName: 'Tropical Fruit World Tickets',
      regionUrlSegment: 'gold-coast',
      productUrlSegment: 'tropical-fruit-world-entry',
      rating: 4.7,
      images: [
        'https://cdn.experienceoz.com.au/assets/product/514/newImage/104089.jpg',
        'https://cdn.experienceoz.com.au/assets/product/514/newImage/104087.jpg',
        'https://cdn.experienceoz.com.au/assets/product/514/newImage/139632.jpg',
        'https://cdn.experienceoz.com.au/assets/product/514/newImage/139633.jpg',
        'https://cdn.experienceoz.com.au/assets/product/514/newImage/139634.jpg',
        'https://cdn.experienceoz.com.au/assets/product/514/newImage/139663.jpg',
      ],
    },
    {
      productId: 517,
      operatorName: 'Aquaduck Gold Coast',
      productName: 'Aquaduck Gold Coast City Tour and River Cruise',
      regionUrlSegment: 'gold-coast',
      productUrlSegment: 'aquaduck',
      rating: 4.7,
      images: [
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/123377.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/111839.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/101101.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/111836.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/111835.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/111838.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/111837.jpg',
      ],
    },
    {
      productId: 13772,
      operatorName: 'Australian Outback Spectacular',
      productName: 'Australian Outback Spectacular Heartland Dinner and Show',
      regionUrlSegment: 'gold-coast',
      productUrlSegment: 'australian-outback-spectacular-heartland-show',
      rating: 4.7,
      images: [
        'https://cdn.experienceoz.com.au/assets/product/13772/newImage/94792.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13772/newImage/104675.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13772/newImage/95473.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13772/newImage/95474.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13772/newImage/95475.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13772/newImage/95476.jpg',
      ],
    },
  ],
  productReviews: {
    total: 1136,
    average: 4.700704225,
    loadedReviews: [
      {
        id: 25421,
        title: 'Currumbin Wildlife Sanctuary Tickets',
        author: 'kvkrlphx',
        summary:
          'Spoke with operator to book ticket. Very helpful and assisted with no fault to use a credit left over from covid shutdown. Good customer service.',
        blurb:
          'Spoke with an operator to book tickets. Very helpful and assisted with no faults from operators, to use a credit left over from covid shutdown. Good customer service. Also helped with other tickets. Made the whole experience to be pleasant. Thank you for your assistance',
        rating: 5,
        date: '2023-04-27 05:55:07',
      },
    ],
    reviews: [
      {
        id: 25421,
        title: 'Currumbin Wildlife Sanctuary Tickets',
        author: 'kvkrlphx',
        summary:
          'Spoke with operator to book ticket. Very helpful and assisted with no fault to use a credit left over from covid shutdown. Good customer service.',
        blurb:
          'Spoke with an operator to book tickets. Very helpful and assisted with no faults from operators, to use a credit left over from covid shutdown. Good customer service. Also helped with other tickets. Made the whole experience to be pleasant. Thank you for your assistance',
        rating: 5,
        date: '2023-04-27 05:55:07',
      },
      {
        id: 25364,
        title: 'Currumbin Wildlife Sanctuary Tickets',
        author: 'erbibkcq',
        summary: 'We absolutely loved it! When we went last time, we will be going again! So much to see and do.',
        blurb:
          'Amazing! Kids loved it so much. Will be back. There is so much to see and do. Lots of animals. The kids loved all the koalas and were able to hold them. Was much easier then driving all the way out to Australia zoo. The kids loved the train ride!!!!!',
        rating: 5,
        date: '2023-02-11 08:09:06',
      },
      {
        id: 25422,
        title: 'Currumbin Wildlife Sanctuary Tickets',
        author: 'kvkrlphx',
        summary: 'This is a very good!',
        blurb: 'This is a very good!',
        rating: 5,
        date: '2023-04-27 05:55:07',
      },
      {
        id: 25423,
        title: 'Currumbin Wildlife Sanctuary Tickets',
        author: 'kvkrlphx',
        summary: 'Would recommend',
        blurb: 'Would recommend',
        rating: 5,
        date: '2023-04-27 05:55:07',
      },
      {
        id: 25424,
        title: 'Currumbin Wildlife Sanctuary Tickets',
        author: 'kvkrlphx',
        summary: 'Very nice',
        blurb: 'Very nice',
        rating: 5,
        date: '2023-04-27 05:55:07',
      },
      {
        id: 25425,
        title: 'Currumbin Wildlife Sanctuary Tickets',
        author: 'kvkrlphx',
        summary: 'Woo hoo',
        blurb: 'Woo hoo',
        rating: 5,
        date: '2023-04-27 05:55:07',
      },
      {
        id: 25426,
        title: 'Currumbin Wildlife Sanctuary Tickets',
        author: 'kvkrlphx',
        summary: 'Woo hoo',
        blurb: 'Woo hoo',
        rating: 4,
        date: '2023-04-27 05:55:07',
      },
      {
        id: 25427,
        title: 'Currumbin Wildlife Sanctuary Tickets',
        author: 'kvkrlphx',
        summary: 'Woo hoo',
        blurb: 'Woo hoo',
        rating: 4,
        date: '2023-04-27 05:55:07',
      },
      {
        id: 25428,
        title: 'Currumbin Wildlife Sanctuary Tickets',
        author: 'kvkrlphx',
        summary: 'Woo hoo',
        blurb: 'Woo hoo',
        rating: 3,
        date: '2023-04-27 05:55:07',
      },
      {
        id: 25429,
        title: 'Currumbin Wildlife Sanctuary Tickets',
        author: 'kvkrlphx',
        summary: 'Woo hoo',
        blurb: 'Woo hoo',
        rating: 3,
        date: '2023-04-27 05:55:07',
      },
    ],
  },
  moreToExplore: [
    {
      id: 517,
      name: 'Aquaduck Gold Coast City Tour and River Cruise',
      urlSegment: 'aquaduck',
      operatorName: 'Aquaduck Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      fromPrice: 50,
      rating: 4,
      images: [
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/123377.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/111839.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/101101.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/111836.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/111835.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/111838.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/111837.jpg',
      ],
    },
    {
      id: 518,
      name: 'Gold Coast Hop On Hop Off All Day Ferry Pass',
      urlSegment: 'aquaduck',
      operatorName: 'Sea World Cruises Terminal',
      canonicalRegionUrlSegment: 'gold-coast',
      fromPrice: 25,
      rating: 5,
      images: [
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/123377.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/123377.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/123377.jpg',
      ],
    },
    {
      id: 519,
      name: 'Gold Coast Buffet Lunch Cruise',
      urlSegment: 'see-world-buffet-lunch-sightseeing-cruise',
      operatorName: 'Sea World Cruises Sightseeing and Dining',
      canonicalRegionUrlSegment: 'gold-coast',
      fromPrice: 89,
      rating: 4.5,
      images: [
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/123377.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/123377.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/123377.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/123377.jpg',
        'https://cdn.experienceoz.com.au/assets/product/517/newImage/123377.jpg',
      ],
    },
  ],
}

export const plpHeaderData: PlpHeader = {
  title: 'Rio De Janeiro',
  text: 'From aerobatic flights to hot air ballooning, skydiving, jet fighters, scenic helicopter rides.',
  image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/153481.jpg',
  ctaLabel: 'See all experiences',
  ctaLink: '/experiences',
}

export const regionEntityDetails: EntityDetails = {
  id: 666,
  name: 'Rio de Janeiro',
  urlSegment: 'rio',
  stateUrlSegment: 'rj',
  title: 'Welcome to Rio de Janeiro',
  heading: 'Things to do in Rio',
  summary: 'Things to do in Rio and surroundings',
  shortDescription: 'Guide on how not to get mugged',
  description: 'In Rio things are crazy, great parties, great beaches and <a href="/test">corrupt politicians.</a>',
  image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/153481.jpg',
  geolocation: { latitude: 22.9068, longitude: 43.1729 },
  quantity: 2,
  offset: 0,
  products: plpProducts,
}

export const regionResponse: PlpResponse = {
  entityType: 'REGION',
  ...defaultPageProperties,
  entityDetails: regionEntityDetails,
  templateDefinition: {
    widgets: [
      {
        name: 'page-description',
        text: 'this is the nicest stuff, thanks.',
      },
      {
        name: 'footer',
      },
      {
        name: 'header',
        title: 'Test Header',
        description: 'This is a test description',
        image: 'https://cdn.experienceoz.com.au/assets/product/7892/newImage/101576.jpg',
        variant: 'full',
      },
      {
        name: 'editorial',
        title: 'Test Header',
        description: 'This is a test description',
        image: 'https://cdn.experienceoz.com.au/assets/product/7892/newImage/101576.jpg',
        location: 'Byron Bay',
      },
      {
        name: 'product-listing',
        title: 'All Theme Park experiences',
      },
      {
        name: 'campaign-promo',
        title: '<span>Go way way</span><span>out of office</span>',
        image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/153570.jpg',
        description: 'Shop gift vouchers',
        link: '/en/gift-vouchers',
      },
      {
        name: 'dynamic-product-carousel',
        label: 'Family Experiences',
        entity: 'region',
        products: [
          {
            name: 'Tweed River and Rainforest Lunch Cruise',
            supplierName: 'Tweed Eco Cruises',
            urlPath: '/gold-coast/river-and-rainforest-cruise',
            fromPrice: 99.0,
            retailPrice: 99.0,
            levy: 0.0,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/523/newImage/120422.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
            ],
            hotDealMessage: 'test',
          },
          {
            name: 'Sunrise Hot Air Ballooning In Byron Bay',
            supplierName: 'Balloon Aloft (International Balloon Flight Company) Byron Bay',
            urlPath: '/byron-bay/hot-air-ballooning-in-byron-bay',
            fromPrice: 295.0,
            retailPrice: 339.0,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/23647/newImage/133710.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
            ],
            hotDealMessage: 'test',
          },
          {
            name: 'Crystal Castle Byron Bay General Admission Tickets',
            supplierName: 'Crystal Castle',
            urlPath: '/byron-bay/crystal-castle',
            fromPrice: 49.0,
            retailPrice: 49.0,
            levy: 0.0,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/3097/newImage/68467.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
            ],
            hotDealMessage: 'test',
          },
          {
            name: 'Byron Bay Dolphin Kayaking Tour',
            supplierName: 'Cape Byron Kayaks',
            urlPath: '/byron-bay/cape-byron-kayaks',
            fromPrice: 89.0,
            retailPrice: 89.0,
            levy: 0.0,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/1493/newImage/113799.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
            ],
            hotDealMessage: 'test',
          },
          {
            name: 'Byron Bay 15,000ft Tandem Skydive',
            supplierName: 'Skydive Byron Bay',
            urlPath: '/byron-bay/skydive-byron-bay',
            fromPrice: 399.0,
            retailPrice: 399.0,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/816/newImage/146373.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
            ],
            hotDealMessage: 'test',
          },
          {
            name: 'Horse Riding Byron Bay Beach Ride',
            supplierName: 'Pegasus Park Equestrian Centre',
            urlPath: '/byron-bay/beach-trail-rides-byron-bay',
            fromPrice: 150.0,
            retailPrice: 150.0,
            levy: 0.0,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/3211/newImage/61893.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
            ],
            hotDealMessage: 'test',
          },
        ],
      },
      {
        name: 'dynamic-product-carousel',
        label: 'Top Experiences',
        entity: 'region',
        products: [
          {
            name: 'Tweed River and Rainforest Lunch Cruise',
            supplierName: 'Tweed Eco Cruises',
            urlPath: '/gold-coast/river-and-rainforest-cruise',
            fromPrice: 99.0,
            retailPrice: 99.0,
            levy: 0.0,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/523/newImage/120422.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
            ],
            hotDealMessage: 'test',
          },
          {
            name: 'Sunrise Hot Air Ballooning In Byron Bay',
            supplierName: 'Balloon Aloft (International Balloon Flight Company) Byron Bay',
            urlPath: '/byron-bay/hot-air-ballooning-in-byron-bay',
            fromPrice: 295.0,
            retailPrice: 339.0,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/23647/newImage/133710.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
            ],
            hotDealMessage: 'test',
          },
          {
            name: 'Crystal Castle Byron Bay General Admission Tickets',
            supplierName: 'Crystal Castle',
            urlPath: '/byron-bay/crystal-castle',
            fromPrice: 49.0,
            retailPrice: 49.0,
            levy: 0.0,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/3097/newImage/68467.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
            ],
            hotDealMessage: 'test',
          },
          {
            name: 'Byron Bay Dolphin Kayaking Tour',
            supplierName: 'Cape Byron Kayaks',
            urlPath: '/byron-bay/cape-byron-kayaks',
            fromPrice: 89.0,
            retailPrice: 89.0,
            levy: 0.0,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/1493/newImage/113799.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
            ],
            hotDealMessage: 'test',
          },
          {
            name: 'Byron Bay 15,000ft Tandem Skydive',
            supplierName: 'Skydive Byron Bay',
            urlPath: '/byron-bay/skydive-byron-bay',
            fromPrice: 399.0,
            retailPrice: 399.0,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/816/newImage/146373.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
            ],
            hotDealMessage: 'test',
          },
          {
            name: 'Horse Riding Byron Bay Beach Ride',
            supplierName: 'Pegasus Park Equestrian Centre',
            urlPath: '/byron-bay/beach-trail-rides-byron-bay',
            fromPrice: 150.0,
            retailPrice: 150.0,
            levy: 0.0,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/3211/newImage/61893.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
            ],
            hotDealMessage: 'test',
          },
        ],
      },
    ],
  },
  error: false,
}

export const voucherValidationResponse = {
  status: 'VALID',
  type: 'EXPERIENCE',
  voucherNumber: '123456789',
  pin: '1234',
  currency: 'AUD',
  balance: 184.85,
  priceGuarantee: '2025-05-09',
  expiry: '2029-05-09 10:41',
  giftImage: 'going-away-2',
  giftSuggestion: {
    id: 26740,
    regionSegment: 'gold-coast',
    urlSegment: 'arro-jetboating-experience-gold-coast',
    name: 'Arro Jet Boating',
    image: 'https://cdn.experienceoz.com.au/assets/product/26740/newImage/159375.jpg',
    options: [
      {
        name: 'Adult',
        quantity: 1,
        unitPrice: 99,
        sessionId: 8273,
        sessionName: 'General Admission',
        sessionTime: '09:00',
      },
    ],
  },
}

export const accountVoucherResponse: RedeemCreditVoucherDetails[] = [
  {
    status: 'VALID',
    voucherNumber: '123456789',
    pin: '1234',
    balance: 184.85,
    expiry: '2025-05-09 10:41',
    giftImage: 2,
    giftSuggestion: {
      id: 26740,
      regionSegment: 'gold-coast',
      urlSegment: 'arro-jetboating-experience-gold-coast',
      name: 'Tweed River and Rainforest Lunch Cruise',
      image: 'https://cdn.experienceoz.com.au/assets/product/523/newImage/120422.jpg',
      options: [
        {
          name: 'Adult',
          quantity: 2,
          unitPrice: 99,
          sessionId: 8273,
          sessionName: 'General Admission',
          sessionTime: '09:00',
        },
      ],
    },
  },
]

export const giftCreditVoucherResponse: RedeemCreditVoucherDetails[] = [
  {
    status: 'VALID',
    voucherNumber: '123456789',
    pin: '1234',
    balance: 50,
    expiry: '2025-05-09 10:41',
    giftImage: 2,
  },
]

export const experienceCreditVoucherResponse: RedeemCreditVoucherDetails[] = [
  {
    status: 'VALID',
    voucherNumber: '123456789',
    pin: '1234',
    balance: 184.85,
    expiry: '2025-05-09 10:41',
    giftImage: 2,
    giftSuggestion: {
      id: 12345,
      regionSegment: 'gold-coast',
      urlSegment: 'arro-jetboating-experience-gold-coast',
      name: 'Currumbin Wildlife Sanctuary Tickets',
      image: 'https://cdn.experienceoz.com.au/assets/product/129/newImage/113793.jpg',
      options: [
        {
          name: 'Adult',
          quantity: 2,
          unitPrice: 64.95,
          sessionId: 8273,
          sessionName: 'General Admission',
          sessionTime: '09:00',
        },
        {
          name: 'Child',
          quantity: 1,
          unitPrice: 54.95,
          sessionId: 8273,
          sessionName: 'General Admission',
          sessionTime: '09:00',
        },
      ],
    },
  },
]

export const combinedCreditVoucherResponse: RedeemCreditVoucherDetails[] = [
  ...experienceCreditVoucherResponse,
  ...giftCreditVoucherResponse,
]

export const ADRVoucherProductResponse: PdpResponse = {
  error: false,
  ...defaultPageProperties,
  entityType: 'PRODUCT',
  productDetails: {
    id: 16158,
    urlSegment: 'experience-oz-gift-voucher',
    allowsExperienceVoucherPurchase: true,
    name: 'Experience Oz Gift Vouchers',
    operatorName: 'Experience Oz Gift Vouchers',
    operatorId: 13038,
    operatorPhone: '+61 397837328',
    canonicalRegionUrlSegment: 'gold-coast',
    bookingRequired: false,
    handlerKey: 'exozGiftCard2',
    title: 'Experience Oz Gift Vouchers - Buy Now | Experience Oz',
    summary:
      'Buy an experience gift voucher online here and let your friends, family or colleagues choose their own experience. Gift vouchers are valid for 5 years.',
    description:
      "Can't decide on an experience to gift? Our<strong> Experience Oz Gift Vouchers</strong> are the perfect choice with a range of options available to suit every budget. Gift differently with an Experience Oz Gift Voucher and let your loved ones choose an experience they'll love. All experience gift vouchers can be redeemed towards 3,000 Australian and New Zealand experiences on offer and are valid for five years.\n\nGive your loved ones a helping hand booking in their bucket list experiences for the first time or simply say thank you with an open-dated experience gift voucher.",
    moreInformation: [
      {
        heading: 'Inclusions',
        content:
          "* $50 or $100 or $150 or $200 voucher for use on any product on Experience Oz. \n\n**Tip** Choose multiples of any of the above mentioned value to give an even *larger* amount if you're feeling really generous!",
      },
      {
        heading: 'Highlights',
        content:
          '* Valid towards any product for Experience Oz\n* Over 3000 experiences across Australia and New Zealand\n* The perfect gift for your loved ones, colleagues or clients\n* Gift Vouchers valued from $50 to $200\n* Choose from a variety of experiences\n* A more flexible, and easy gifting option if you are far away, or time poor...',
      },
      {
        heading: 'Additional Information',
        content:
          "* This voucher can be used for any experience listed on the Experience Oz website\n* If redeeming an experience that is of higher value than the selected amount, the redeemer is required to pay the difference.\n* If redeeming an experience that is less than the selected amount, the remaining value will be added as a credit to the redeemer's Experience Oz customer account\n* If the person redeeming doesn't have a Experience Oz customer account, one can be created for them to have the credit added",
      },
      {
        heading: 'Terms And Conditions',
        content: '* Experience Oz does not offer any refunds for experience gift vouchers',
      },
      {
        heading: 'Booking Information',
        content:
          '* A gift voucher will be received by email after purchase\n* Give your gift voucher to your Gift Recipient, they will then need to follow the instructions to\nredeem their experience\n* Gift vouchers are valid for 5 years from the date of purchase\n* Upon redemption a confirmation email/ticket will be sent to the gift recipient',
      },
    ],
    helpCode: 'D2B-3F1E',
    fromPrice: 50.0,
    highlights: [
      'The voucher is for use on over 2,500+ adventures',
      'Vouchers are valid for up to 5 years',
      'Gift Vouchers valued from $50 to $200',
      'Easy to give, flexible to exchange',
      'The perfect gift for friends, family or colleagues',
    ],
    decorators: [
      {
        id: 1,
        name: 'BEST_SELLER',
        key: 'bestSeller',
        label: 'Best seller',
      },
      {
        id: 12,
        name: 'LOW_FITNESS_LEVEL',
        key: 'lowFitnessLevel',
        label: 'Fitness level low',
      },
      {
        id: 13,
        name: 'MEDIUM_FITNESS_LEVEL',
        key: 'mediumFitnessLevel',
        label: 'Fitness level moderate',
      },
      {
        id: 14,
        name: 'HIGH_FITNESS_LEVEL',
        key: 'highFitnessLevel',
        label: 'Fitness level high',
      },
      {
        id: 15,
        name: 'LOW_ADRENALINE_LEVEL',
        key: 'lowAdrenalineLevel',
        label: 'Intensity level low',
      },
      {
        id: 16,
        name: 'MEDIUM_ADRENALINE_LEVEL',
        key: 'mediumAdrenalineLevel',
        label: 'Intensity level moderate',
      },
      {
        id: 17,
        name: 'HIGH_ADRENALINE_LEVEL',
        key: 'highAdrenalineLevel',
        label: 'Intensity level high',
      },
    ],
    productSessions: [
      {
        id: 36458,
        sessionName: 'Gift Voucher',
        hotDeal: { message: 'Some message', expiry: 0 },
        evSession: true,
        productOptions: [
          {
            id: 105148,
            name: '$50 voucher',
            price: 50.0,
            retailPrice: 50.0,
            type: 'CUSTOM',
            maxQty: 10,
            minQty: 1,
            defaultQty: 0,
            multiple: 1,
            weight: 1,
            isFromPrice: true,
          },
          {
            id: 105149,
            name: '$100 voucher',
            price: 100.0,
            retailPrice: 100.0,
            type: 'CUSTOM',
            maxQty: 10,
            minQty: 1,
            defaultQty: 0,
            multiple: 1,
            weight: 1,
            isFromPrice: false,
          },
          {
            id: 105150,
            name: '$150 voucher',
            price: 150.0,
            retailPrice: 150.0,
            type: 'CUSTOM',
            maxQty: 10,
            minQty: 1,
            defaultQty: 0,
            multiple: 1,
            weight: 1,
            isFromPrice: false,
          },
          {
            id: 105151,
            name: '$200 voucher',
            price: 200.0,
            retailPrice: 200.0,
            type: 'CUSTOM',
            maxQty: 10,
            minQty: 1,
            defaultQty: 0,
            multiple: 1,
            weight: 1,
            isFromPrice: false,
          },
        ],
        hasPickups: 'none',
        levy: 0.0,
        isExtra: false,
      },
    ],
    geolocations: [
      {
        latitude: 67.0,
        longitude: 41.0,
        address: 'uhxzpwegth, xvjnpj, lby 9989',
        label: 'Experience Oz',
      },
    ],
    images: [
      'https://cdn.experienceoz.com.au/assets/product/16158/newImage/145006.jpg',
      'https://cdn.experienceoz.com.au/assets/product/16158/newImage/114114.jpg',
      'https://cdn.experienceoz.com.au/assets/product/16158/newImage/114116.jpg',
      'https://cdn.experienceoz.com.au/assets/product/16158/newImage/114118.jpg',
      'https://cdn.experienceoz.com.au/assets/product/16158/newImage/114117.jpg',
      'https://cdn.experienceoz.com.au/assets/product/16158/newImage/114115.jpg',
    ],
    timestamp: 1688474814833,
    timezone: 'Australia/Queensland',
  },
  productRecommendations: [
    {
      productId: 5468,
      operatorName: 'Gold Coast Sightseeing & Dining Cruises',
      productName: 'Gold Coast Buffet Lunch Cruise',
      regionUrlSegment: 'gold-coast',
      productUrlSegment: 'see-world-buffet-lunch-sightseeing-cruise',
      rating: 4.7,
      images: [
        'https://cdn.experienceoz.com.au/assets/product/5468/newImage/143908.jpg',
        'https://cdn.experienceoz.com.au/assets/product/5468/newImage/143909.jpg',
        'https://cdn.experienceoz.com.au/assets/product/5468/newImage/143910.jpg',
        'https://cdn.experienceoz.com.au/assets/product/5468/newImage/143911.jpg',
        'https://cdn.experienceoz.com.au/assets/product/5468/newImage/143912.jpg',
      ],
    },
    {
      productId: 11543,
      operatorName: 'SkyPoint Observation Deck Entry',
      productName: 'SkyPoint Observation Deck and Dine Combo Ticket',
      regionUrlSegment: 'gold-coast',
      productUrlSegment: 'skypoint-observation-deck-and-dine-combo',
      rating: 4.6,
      images: [
        'https://cdn.experienceoz.com.au/assets/product/11543/newImage/115762.jpg',
        'https://cdn.experienceoz.com.au/assets/product/11543/newImage/115764.jpg',
        'https://cdn.experienceoz.com.au/assets/product/11543/newImage/115765.jpg',
        'https://cdn.experienceoz.com.au/assets/product/11543/newImage/115766.jpg',
        'https://cdn.experienceoz.com.au/assets/product/11543/newImage/115767.jpg',
        'https://cdn.experienceoz.com.au/assets/product/11543/newImage/115768.jpg',
      ],
    },
    {
      productId: 13430,
      operatorName: 'Hot Air Ballooning over the Gold Coast',
      productName: 'Gold Coast Hot Air Balloon Flight with BONUS photo package',
      regionUrlSegment: 'gold-coast',
      productUrlSegment: 'go-ballooning-hot-air-balloon-flight',
      rating: 4.3,
      images: [
        'https://cdn.experienceoz.com.au/assets/product/13430/newImage/133265.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13430/newImage/133266.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13430/newImage/133267.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13430/newImage/133268.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13430/newImage/133269.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13430/newImage/133270.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13430/newImage/133271.jpg',
      ],
    },
  ],
  productReviews: {
    total: 16,
    average: 4.6875,
    loadedReviews: [
      {
        id: 25421,
        title: 'Currumbin Wildlife Sanctuary Tickets',
        author: 'kvkrlphx',
        summary:
          'Spoke with operator to book ticket. Very helpful and assisted with no fault to use a credit left over from covid shutdown. Good customer service.',
        blurb:
          'Spoke with an operator to book tickets. Very helpful and assisted with no faults from operators, to use a credit left over from covid shutdown. Good customer service. Also helped with other tickets. Made the whole experience to be pleasant. Thank you for your assistance',
        rating: 5,
        date: '2023-04-27 05:55:07',
      },
    ],
    ratings: [
      {
        rating: 2,
        total: 1,
      },
      {
        rating: 4,
        total: 2,
      },
      {
        rating: 5,
        total: 13,
      },
    ],
    reviews: [
      {
        id: 25545,
        title: 'Experience Oz Gift Vouchers',
        author: 'fnidnvfr',
        summary: 'This is a really great gift to give to family and friends',
        blurb:
          "Really easy to use and a great gift to send to family and friends. It's also great just to keep to purchase gift cards to keep aside for future holidays around Australia. It was also super easy to keep all of your purchases organised with the use of the website.",
        rating: 5,
        date: '2023-05-30 18:05:41',
      },
      {
        id: 25536,
        title: 'Experience Oz Gift Vouchers',
        author: 'nazupudy',
        summary: 'Great idea for a gift and the vouchers are delivered instantly',
        blurb:
          "Gift vouchers are great. The best part is that you can buy an experience and if the person who receives it doesn't like it, they can exchange it for another one, which is great when you want to give someone an actual gift idea rather than vouchers :))",
        rating: 5,
        date: '2023-05-29 15:04:34',
      },
      {
        id: 25319,
        title: 'Experience Oz Gift Vouchers',
        author: 'vusqndar',
        summary: 'Fantastic overall experience with the company. Very pleased with easy access online.',
        blurb:
          'My review is great.\nNot only did my gran kids enjoy the vouchers, they got to use them at Australia Zoo.\nWhich they had an awesome day.\nUsing voucher was easy no issues at all. Just scan barcode and put pin in.\nI will use experience Oz always now as I like not giving the cash as presents this a safer way.\nPlus they can use it on perfect entertainment.',
        rating: 5,
        date: '2023-01-03 12:29:03',
      },
      {
        id: 25297,
        title: 'Experience Oz Gift Vouchers',
        author: 'pgwzlvkt',
        summary: 'I buy Gift Vouchers in advance to keep one handy at all times!',
        blurb:
          "I try to make sure that I keep my account stocked with a gift voucher at all times. That way if there's a special event coming up I can use it or give it away as a present. Every time there's a sale on I first look for an experience to purchase but if nothing catches my fancy I buy a gift voucher instead. That way I can still take advantage of the sale but choose something suitable without needing to rush the process. The gift card redemption process is easy and I've done it many times without any problem.",
        rating: 5,
        date: '2023-01-02 04:56:21',
      },
      {
        id: 25132,
        title: 'Experience Oz Gift Vouchers',
        author: 'itcmrakn',
        summary: 'Will be a great Christmas present for family to enjoy.',
        blurb:
          'I bought vouchers for family members to be able to choose their own activities to enjoy with their family or spoil themselves! Lots of great experiences and activities to do all over the country. Really hope they enjoy their vouchers. Was easy to buy and got a discount on my voucher so all the more reason to buy a voucher 😂',
        rating: 5,
        date: '2022-11-30 17:16:40',
      },
      {
        id: 25099,
        title: 'Experience Oz Gift Vouchers',
        author: 'jueiporx',
        summary: 'Glad I was able to get something that allows me to choose as I found it difficult to decide.',
        blurb:
          "Found the experiences listed quite limited. Wasn't able to find the right thing so ended up with a voucher, which I can definitely see myself using sometime in the future. When you aren't sure what to do, when you're going or where you plan to go, voucher is definitely the way to go, even as a great gift idea!",
        rating: 4,
        date: '2022-11-23 09:30:21',
      },
      {
        id: 25044,
        title: 'Experience Oz Gift Vouchers',
        author: 'rlmylnne',
        summary: 'Purchased an Experience Oz gift voucher to purchase a SeaWorld annual pass',
        blurb:
          "I purchased a gift voucher but haven't bought anything with the voucher yet so I don't have much to review however I bought the voucher to purchase a SeaWorld annual pass then realized that Experience Oz doesn't sell them all now I will have to buy the annual pass at the full rate",
        rating: 4,
        date: '2022-11-12 23:56:50',
      },
      {
        id: 24985,
        title: 'Experience Oz Gift Vouchers',
        author: 'shtvuxpl',
        summary: 'Super easy to find and purchase. Great variety in dollar amounts.',
        blurb:
          "I had won dollars off for Experience Oz from the Maccas Monopoly, because I wasn't ready to buy a package at the time I thought I'd buy vouchers with them to another time. It worked perfectly, so easy to do and find, great different value offers on vouchers",
        rating: 5,
        date: '2022-10-31 08:10:05',
      },
      {
        id: 24945,
        title: 'Experience Oz Gift Vouchers',
        author: 'tuwaoqyl',
        summary: 'Experience Oz vouchers are great presents made easy.',
        blurb:
          'These gift vouchers are so useful when you want to give someone an experience that they will remember forever, rather than a physical gift.  The beauty is they also get to choose their own experience, and if they choose too, they can also pay extra themselves to experience something even more special than I could afford.',
        rating: 5,
        date: '2022-10-22 17:21:43',
      },
      {
        id: 24893,
        title: 'Experience Oz Gift Vouchers',
        author: 'jcxsurez',
        summary: 'as always a great purchase and love the way its presented and able to give',
        blurb:
          'love the way they present the print at home gift cards, they basically come as as card, you just print off and has all the information on it so when you give it as we gift you know they will know the steps to take to get what they want from the gift card itself',
        rating: 5,
        date: '2022-10-11 14:16:53',
      },
      {
        id: 24469,
        title: 'Experience Oz Gift Vouchers',
        author: 'bxrzfcaa',
        summary: "Purchased the Gift Vouchers for my friend's birthday.",
        blurb:
          "Purchased the Gift Vouchers for my friend's birthday. Easy process with multiple payment option. Transaction is complete within 5 minutes and voucher is received instantly and forward to my friend in time for her birthday. Will use the service in the near future.",
        rating: 5,
        date: '2022-07-05 11:25:03',
      },
      {
        id: 24409,
        title: 'Experience Oz Gift Vouchers',
        author: 'jcnzlijf',
        summary:
          'No option as a gift. I purchased the voucher as a gift for my son but was not given the option to send it to him!',
        blurb:
          'I am in UK and purchased this voucher as a gift for my son in Australia. There was no option to have the voucher sent to him either by email or by post. It actually defeats the purpose of such a gift if you cannot send it to the recipient! Gift vouchers normally go to the person who is to receive the gift.',
        rating: 2,
        date: '2022-06-16 00:21:35',
      },
      {
        id: 24020,
        title: 'Experience Oz Gift Vouchers',
        author: 'snvjnedu',
        summary: 'Clever idea for an individual experience or for the person who has everything.',
        blurb:
          "It was so easy to get this organised especially for a grandson's birthday when he lives so far inland that there are NO experiences he can do nearby. I have the voucher so he can use it on whatever he fancies when he comes to the big smoke...lol. I could have picked experiences for him but this way an open voucher means he can choose for himself.",
        rating: 5,
        date: '2022-01-18 08:08:47',
      },
      {
        id: 23797,
        title: 'Experience Oz Gift Vouchers',
        author: 'dnrycbsq',
        summary:
          'I received the voucher almost instantly. I was very happy that it lasts 3 years and I can choose anything I want to do.',
        blurb:
          "I received the voucher almost instantly. Now I get to use it whenever I want over the next 3 years, on whatever I want. I can't wait to see what adventure this voucher takes me on. It is perfect for me as I am planning on going off to travel Australia which means it could get used in any state across Australia.",
        rating: 5,
        date: '2021-10-25 08:11:34',
      },
      {
        id: 23676,
        title: 'Experience Oz Gift Vouchers',
        author: 'mvjzfein',
        summary:
          'Gift vouchers are fantastic, the person can use it as they please no need to choose anything for them and 3 years to use.',
        blurb:
          'Gift vouchers are fantastic the person can use it as they please no need to scroll through and choose something they might not even be interested in they also get three years to use it and they can use it on different experiences if they want not just on one.',
        rating: 5,
        date: '2021-09-09 19:32:26',
      },
      {
        id: 23017,
        title: 'Experience Oz Gift Vouchers',
        author: 'zjywzdxj',
        summary:
          'Great gift idea for someone far away. Easy to send as well without worrying if it will get there on time.',
        blurb:
          "I'm in WA and my nephew lives in Queensland. As we are so far from him, this was a great option as a gift idea. I've used Experience Oz before and I knew the website was easy to use.  The voucher was easy to forward on to him. He has lots of adventures to choose from!",
        rating: 5,
        date: '2021-03-17 22:08:59',
      },
    ],
  },
  moreToExplore: [
    {
      id: 23660,
      name: 'Village Roadshow 3 Day Escape Pass',
      urlSegment: 'escape-pass',
      summary:
        "Any 3 days entry to Warner Bros. Movie World, Sea World & Wet'n'Wild over 5 consecutive days from first visit, Cheaper than front gate prices!",
      operatorName: 'Village Roadshow Theme Parks Tickets',
      operatorId: 9496,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 179.0,
      retailPrice: 189.0,
      decorators: [
        {
          id: 1,
          name: 'BEST_SELLER',
          key: 'bestSeller',
          label: 'Best seller',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 15,
          name: 'LOW_ADRENALINE_LEVEL',
          key: 'lowAdrenalineLevel',
          label: 'Intensity level low',
        },
        {
          id: 16,
          name: 'MEDIUM_ADRENALINE_LEVEL',
          key: 'mediumAdrenalineLevel',
          label: 'Intensity level moderate',
        },
        {
          id: 17,
          name: 'HIGH_ADRENALINE_LEVEL',
          key: 'highAdrenalineLevel',
          label: 'Intensity level high',
        },
        {
          id: 25,
          name: 'WHEELCHAIR_FRIENDLY',
          key: 'wheelchairFriendly',
          label: 'Wheelchair friendly',
        },
      ],
      geolocations: [
        {
          latitude: 45.0,
          longitude: 44.0,
          address: 'iuymmxdrgl, srznmp, lby 1273',
          label: 'Village Roadshow Theme Parks',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/23660/newImage/134002.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23660/newImage/134004.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23660/newImage/134006.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23660/newImage/144938.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23660/newImage/134007.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23660/newImage/134005.jpg',
      ],
    },
    {
      id: 23653,
      name: 'Dreamworld 1 Day Ticket',
      urlSegment: '1-day-ticket-dreamworld',
      summary:
        'Book your 1 day Dreamworld tickets here to receive express entry into Dreamworld + one BONUS ticket to ride pass on one ride valued at $24.95!',
      operatorName: 'Dreamworld Tickets',
      operatorId: 7095,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 109.0,
      retailPrice: 119.0,
      hotDeals: [
        {
          productSessionId: 39477,
          message: 'SALE Adults at kids prices. Valid for sale and use until 16th July 2023.',
          expiry: 19554,
        },
      ],
      decorators: [
        {
          id: 3,
          name: 'SKIP_THE_QUEUE',
          key: 'skipTheQueue',
          label: 'Skip the queue',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 15,
          name: 'LOW_ADRENALINE_LEVEL',
          key: 'lowAdrenalineLevel',
          label: 'Intensity level low',
        },
        {
          id: 16,
          name: 'MEDIUM_ADRENALINE_LEVEL',
          key: 'mediumAdrenalineLevel',
          label: 'Intensity level moderate',
        },
        {
          id: 17,
          name: 'HIGH_ADRENALINE_LEVEL',
          key: 'highAdrenalineLevel',
          label: 'Intensity level high',
        },
        {
          id: 25,
          name: 'WHEELCHAIR_FRIENDLY',
          key: 'wheelchairFriendly',
          label: 'Wheelchair friendly',
        },
      ],
      geolocations: [
        {
          latitude: 14.0,
          longitude: 47.0,
          address: 'bzvexzjmgf, jibfdg, lby 9992',
          label: 'Dreamworld',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/23653/newImage/133887.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23653/newImage/133891.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23653/newImage/133893.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23653/newImage/134367.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23653/newImage/134368.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23653/newImage/134369.jpg',
      ],
    },
    {
      id: 12333,
      name: '7 Day Theme Park Pass',
      urlSegment: 'theme-park-7-day-pass',
      summary:
        "Purchase a 7 day pass and gain entry to Movie World, Sea World, Wet'n'Wild and Paradise Country over 7 consecutive days.",
      operatorName: 'Village Roadshow Super Pass',
      operatorId: 9498,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 199.0,
      retailPrice: 209.0,
      levy: 0.0,
      decorators: [
        {
          id: 1,
          name: 'BEST_SELLER',
          key: 'bestSeller',
          label: 'Best seller',
        },
        {
          id: 2,
          name: 'MOST_POPULAR',
          key: 'mostPopular',
          label: 'Most popular',
        },
        {
          id: 3,
          name: 'SKIP_THE_QUEUE',
          key: 'skipTheQueue',
          label: 'Skip the queue',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 15,
          name: 'LOW_ADRENALINE_LEVEL',
          key: 'lowAdrenalineLevel',
          label: 'Intensity level low',
        },
        {
          id: 16,
          name: 'MEDIUM_ADRENALINE_LEVEL',
          key: 'mediumAdrenalineLevel',
          label: 'Intensity level moderate',
        },
        {
          id: 17,
          name: 'HIGH_ADRENALINE_LEVEL',
          key: 'highAdrenalineLevel',
          label: 'Intensity level high',
        },
        {
          id: 25,
          name: 'WHEELCHAIR_FRIENDLY',
          key: 'wheelchairFriendly',
          label: 'Wheelchair friendly',
        },
      ],
      geolocations: [
        {
          latitude: 99.0,
          longitude: 94.0,
          address: 'idxbxyjsqh, dlxghz, lby 1273',
          label: 'Village Roadshow Theme Parks',
        },
        {
          latitude: 88.0,
          longitude: 68.0,
          address: 'idxbxyjsqh, aftxkq, lby 9989',
          label: 'Sea World',
        },
        {
          latitude: 46.0,
          longitude: 19.0,
          address: 'nkpksmcdyv, srznmp, lby 1273',
          label: "Wet'n'Wild Gold Coast",
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/12333/newImage/133277.jpg',
        'https://cdn.experienceoz.com.au/assets/product/12333/newImage/134298.jpg',
        'https://cdn.experienceoz.com.au/assets/product/12333/newImage/134297.jpg',
        'https://cdn.experienceoz.com.au/assets/product/12333/newImage/134301.jpg',
        'https://cdn.experienceoz.com.au/assets/product/12333/newImage/144963.jpg',
        'https://cdn.experienceoz.com.au/assets/product/12333/newImage/134300.jpg',
      ],
    },
    {
      id: 129,
      name: 'Currumbin Wildlife Sanctuary Tickets',
      urlSegment: 'currumbin-wildlife-sanctuary-general-admission',
      summary:
        "Buy tickets to the Gold Coast's popular Currumbin Wildlife Sanctuary attraction and receive EXCLUSIVE in-park discount vouchers!",
      operatorName: 'Currumbin Wildlife Sanctuary',
      operatorId: 53,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: false,
      fromPrice: 59.95,
      retailPrice: 59.95,
      levy: 0.0,
      hotDeals: [
        {
          productSessionId: 14099,
          message: 'EXCLUSIVE in-park discount vouchers with savings on retail and food and beverage!',
          expiry: 19722,
        },
      ],
      decorators: [
        {
          id: 1,
          name: 'BEST_SELLER',
          key: 'bestSeller',
          label: 'Best seller',
        },
        {
          id: 2,
          name: 'MOST_POPULAR',
          key: 'mostPopular',
          label: 'Most popular',
        },
        {
          id: 3,
          name: 'SKIP_THE_QUEUE',
          key: 'skipTheQueue',
          label: 'Skip the queue',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 15,
          name: 'LOW_ADRENALINE_LEVEL',
          key: 'lowAdrenalineLevel',
          label: 'Intensity level low',
        },
        {
          id: 25,
          name: 'WHEELCHAIR_FRIENDLY',
          key: 'wheelchairFriendly',
          label: 'Wheelchair friendly',
        },
      ],
      geolocations: [
        {
          latitude: 64.0,
          longitude: 52.0,
          address: 'suzubqyppj, bvhvuz, lby 4753',
          label: 'Currumbin Wildlife Sanctuary',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/129/newImage/113793.jpg',
        'https://cdn.experienceoz.com.au/assets/product/129/newImage/113791.jpg',
        'https://cdn.experienceoz.com.au/assets/product/129/newImage/113792.jpg',
        'https://cdn.experienceoz.com.au/assets/product/129/newImage/137346.jpg',
        'https://cdn.experienceoz.com.au/assets/product/129/newImage/137381.jpg',
        'https://cdn.experienceoz.com.au/assets/product/129/newImage/137382.jpg',
      ],
    },
  ],
}

export const searchProperties: SearchProperties = {
  regions: [
    { id: 1, name: 'Woodridge', urlSegment: 'woodridge' },
    { id: 2, name: 'Loganlea', urlSegment: 'loganlea' },
  ],
  categories: [
    { id: 1, name: 'Pubs', urlSegment: 'pubs', categoryType: 'EXPERIENCE' },
    { id: 1, name: 'Attractions', urlSegment: 'attractions', categoryType: 'EXPERIENCE' },
  ],
}

export const searchResponse = {
  agentConfiguration,
  searchProperties,
  topbarConfiguration,
  part1: 'gold-coast',
  part2: 'tours',
  query: {
    lang: 'en',
    part1: 'sydney',
    part2: 'tours',
    part3: '2023-09-11_2023-09-30',
  },
  part1EntityType: 'REGION',
  part2EntityType: 'CATEGORY',
  products: [
    {
      id: 23660,
      name: 'Village Roadshow 3 Day Escape Pass',
      urlSegment: 'escape-pass',
      summary:
        "Any 3 days entry to Warner Bros. Movie World, Sea World & Wet'n'Wild over 5 consecutive days from first visit, Cheaper than front gate prices!",
      operatorName: 'Village Roadshow Theme Parks Tickets',
      operatorId: 9496,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 179.0,
      retailPrice: 189.0,
      rating: 4.6,
      decorators: [
        {
          id: 1,
          name: 'BEST_SELLER',
          key: 'bestSeller',
          label: 'Best seller',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 15,
          name: 'LOW_ADRENALINE_LEVEL',
          key: 'lowAdrenalineLevel',
          label: 'Intensity level low',
        },
        {
          id: 16,
          name: 'MEDIUM_ADRENALINE_LEVEL',
          key: 'mediumAdrenalineLevel',
          label: 'Intensity level moderate',
        },
        {
          id: 17,
          name: 'HIGH_ADRENALINE_LEVEL',
          key: 'highAdrenalineLevel',
          label: 'Intensity level high',
        },
        {
          id: 25,
          name: 'WHEELCHAIR_FRIENDLY',
          key: 'wheelchairFriendly',
          label: 'Wheelchair friendly',
        },
      ],
      geolocations: [
        {
          latitude: 45.0,
          longitude: 44.0,
          address: 'iuymmxdrgl, srznmp, lby 1273',
          label: 'Village Roadshow Theme Parks',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/23660/newImage/134002.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23660/newImage/134004.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23660/newImage/134006.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23660/newImage/144938.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23660/newImage/134007.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23660/newImage/134005.jpg',
      ],
    },
    {
      id: 12333,
      name: '7 Day Theme Park Pass',
      urlSegment: 'theme-park-7-day-pass',
      summary:
        "Purchase a 7 day pass and gain entry to Movie World, Sea World, Wet'n'Wild and Paradise Country over 7 consecutive days.",
      operatorName: 'Village Roadshow Super Pass',
      operatorId: 9498,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 199.0,
      retailPrice: 209.0,
      levy: 0.0,
      rating: 4.8,
      decorators: [
        {
          id: 1,
          name: 'BEST_SELLER',
          key: 'bestSeller',
          label: 'Best seller',
        },
        {
          id: 2,
          name: 'MOST_POPULAR',
          key: 'mostPopular',
          label: 'Most popular',
        },
        {
          id: 3,
          name: 'SKIP_THE_QUEUE',
          key: 'skipTheQueue',
          label: 'Skip the queue',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 15,
          name: 'LOW_ADRENALINE_LEVEL',
          key: 'lowAdrenalineLevel',
          label: 'Intensity level low',
        },
        {
          id: 16,
          name: 'MEDIUM_ADRENALINE_LEVEL',
          key: 'mediumAdrenalineLevel',
          label: 'Intensity level moderate',
        },
        {
          id: 17,
          name: 'HIGH_ADRENALINE_LEVEL',
          key: 'highAdrenalineLevel',
          label: 'Intensity level high',
        },
        {
          id: 25,
          name: 'WHEELCHAIR_FRIENDLY',
          key: 'wheelchairFriendly',
          label: 'Wheelchair friendly',
        },
      ],
      geolocations: [
        {
          latitude: 99.0,
          longitude: 94.0,
          address: 'idxbxyjsqh, dlxghz, lby 1273',
          label: 'Village Roadshow Theme Parks',
        },
        {
          latitude: 88.0,
          longitude: 68.0,
          address: 'idxbxyjsqh, aftxkq, lby 9989',
          label: 'Sea World',
        },
        {
          latitude: 46.0,
          longitude: 19.0,
          address: 'nkpksmcdyv, srznmp, lby 1273',
          label: "Wet'n'Wild Gold Coast",
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/12333/newImage/133277.jpg',
        'https://cdn.experienceoz.com.au/assets/product/12333/newImage/134298.jpg',
        'https://cdn.experienceoz.com.au/assets/product/12333/newImage/134297.jpg',
        'https://cdn.experienceoz.com.au/assets/product/12333/newImage/134301.jpg',
        'https://cdn.experienceoz.com.au/assets/product/12333/newImage/144963.jpg',
        'https://cdn.experienceoz.com.au/assets/product/12333/newImage/134300.jpg',
      ],
    },
    {
      id: 23652,
      name: 'Dreamworld, WhiteWater World & SkyPoint 3 Day Ticket',
      urlSegment: '3-day-pass-get-6-months',
      summary:
        'Buy a 3 Day ticket to Dreamworld, WhiteWater World and SkyPoint + one BONUS ticket to ride pass on one ride valued at $24.95!',
      operatorName: 'Dreamworld Tickets',
      operatorId: 7095,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 149.0,
      retailPrice: 159.0,
      rating: 4.6,
      decorators: [
        {
          id: 1,
          name: 'BEST_SELLER',
          key: 'bestSeller',
          label: 'Best seller',
        },
        {
          id: 3,
          name: 'SKIP_THE_QUEUE',
          key: 'skipTheQueue',
          label: 'Skip the queue',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 15,
          name: 'LOW_ADRENALINE_LEVEL',
          key: 'lowAdrenalineLevel',
          label: 'Intensity level low',
        },
        {
          id: 16,
          name: 'MEDIUM_ADRENALINE_LEVEL',
          key: 'mediumAdrenalineLevel',
          label: 'Intensity level moderate',
        },
        {
          id: 17,
          name: 'HIGH_ADRENALINE_LEVEL',
          key: 'highAdrenalineLevel',
          label: 'Intensity level high',
        },
        {
          id: 25,
          name: 'WHEELCHAIR_FRIENDLY',
          key: 'wheelchairFriendly',
          label: 'Wheelchair friendly',
        },
      ],
      geolocations: [
        {
          latitude: 14.0,
          longitude: 47.0,
          address: 'bzvexzjmgf, jibfdg, lby 9992',
          label: 'Dreamworld',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/23652/newImage/138561.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23652/newImage/133898.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23652/newImage/133901.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23652/newImage/133899.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23652/newImage/133902.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23652/newImage/133900.jpg',
      ],
    },
    {
      id: 129,
      name: 'Currumbin Wildlife Sanctuary Tickets',
      urlSegment: 'currumbin-wildlife-sanctuary-general-admission',
      summary:
        "Buy tickets to the Gold Coast's popular Currumbin Wildlife Sanctuary attraction and receive EXCLUSIVE in-park discount vouchers!",
      operatorName: 'Currumbin Wildlife Sanctuary',
      operatorId: 53,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: false,
      fromPrice: 59.95,
      retailPrice: 59.95,
      levy: 0.0,
      rating: 4.7,
      hotDeals: [
        {
          productSessionId: 14099,
          message: 'EXCLUSIVE in-park discount vouchers with savings on retail and food and beverage!',
          expiry: 19722,
        },
      ],
      decorators: [
        {
          id: 1,
          name: 'BEST_SELLER',
          key: 'bestSeller',
          label: 'Best seller',
        },
        {
          id: 2,
          name: 'MOST_POPULAR',
          key: 'mostPopular',
          label: 'Most popular',
        },
        {
          id: 3,
          name: 'SKIP_THE_QUEUE',
          key: 'skipTheQueue',
          label: 'Skip the queue',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 15,
          name: 'LOW_ADRENALINE_LEVEL',
          key: 'lowAdrenalineLevel',
          label: 'Intensity level low',
        },
        {
          id: 25,
          name: 'WHEELCHAIR_FRIENDLY',
          key: 'wheelchairFriendly',
          label: 'Wheelchair friendly',
        },
      ],
      geolocations: [
        {
          latitude: 64.0,
          longitude: 52.0,
          address: 'suzubqyppj, bvhvuz, lby 4753',
          label: 'Currumbin Wildlife Sanctuary',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/129/newImage/113793.jpg',
        'https://cdn.experienceoz.com.au/assets/product/129/newImage/113791.jpg',
        'https://cdn.experienceoz.com.au/assets/product/129/newImage/113792.jpg',
        'https://cdn.experienceoz.com.au/assets/product/129/newImage/137346.jpg',
        'https://cdn.experienceoz.com.au/assets/product/129/newImage/137381.jpg',
        'https://cdn.experienceoz.com.au/assets/product/129/newImage/137382.jpg',
      ],
    },
    {
      id: 23653,
      name: 'Dreamworld 1 Day Ticket',
      urlSegment: '1-day-ticket-dreamworld',
      summary:
        'Book your 1 day Dreamworld tickets here to receive express entry into Dreamworld + one BONUS ticket to ride pass on one ride valued at $24.95!',
      operatorName: 'Dreamworld Tickets',
      operatorId: 7095,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 105.0,
      retailPrice: 115.0,
      rating: 4.2,
      decorators: [
        {
          id: 3,
          name: 'SKIP_THE_QUEUE',
          key: 'skipTheQueue',
          label: 'Skip the queue',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 15,
          name: 'LOW_ADRENALINE_LEVEL',
          key: 'lowAdrenalineLevel',
          label: 'Intensity level low',
        },
        {
          id: 16,
          name: 'MEDIUM_ADRENALINE_LEVEL',
          key: 'mediumAdrenalineLevel',
          label: 'Intensity level moderate',
        },
        {
          id: 17,
          name: 'HIGH_ADRENALINE_LEVEL',
          key: 'highAdrenalineLevel',
          label: 'Intensity level high',
        },
        {
          id: 25,
          name: 'WHEELCHAIR_FRIENDLY',
          key: 'wheelchairFriendly',
          label: 'Wheelchair friendly',
        },
      ],
      geolocations: [
        {
          latitude: 14.0,
          longitude: 47.0,
          address: 'bzvexzjmgf, jibfdg, lby 9992',
          label: 'Dreamworld',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/23653/newImage/133887.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23653/newImage/133891.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23653/newImage/133893.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23653/newImage/134367.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23653/newImage/134368.jpg',
        'https://cdn.experienceoz.com.au/assets/product/23653/newImage/134369.jpg',
      ],
    },
    {
      id: 13772,
      name: 'Australian Outback Spectacular Heartland Dinner and Show',
      urlSegment: 'australian-outback-spectacular-heartland-show',
      summary:
        "Australian Outback Spectacular Heartland show includes a 3-course dinner, drinks and a free stockman's hat. Book your ticket online here.",
      operatorName: 'Australian Outback Spectacular ExOz',
      operatorId: 4115,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 109.99,
      retailPrice: 109.99,
      levy: 0.0,
      rating: 4.7,
      decorators: [
        {
          id: 1,
          name: 'BEST_SELLER',
          key: 'bestSeller',
          label: 'Best seller',
        },
        {
          id: 5,
          name: 'MEALS_INCLUDED',
          key: 'mealsIncluded',
          label: 'Meals included',
        },
        {
          id: 25,
          name: 'WHEELCHAIR_FRIENDLY',
          key: 'wheelchairFriendly',
          label: 'Wheelchair friendly',
        },
      ],
      geolocations: [
        {
          latitude: 99.0,
          longitude: 94.0,
          address: 'idxbxyjsqh, dlxghz, lby 1273',
          label: 'Australian Outback Spectacular',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/13772/newImage/94792.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13772/newImage/104675.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13772/newImage/95473.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13772/newImage/95474.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13772/newImage/95475.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13772/newImage/95476.jpg',
      ],
    },
    {
      id: 9011,
      name: 'Gold Coast Sightseeing Dinner Cruise',
      urlSegment: 'sunset-cruise-with-savoury-platter',
      summary:
        'Enjoy a sightseeing dinner cruise including fresh prawns, roast meats, desserts and more as you take in the city lights of the Gold Coast skyline.',
      operatorName: 'Sea World Cruises Sightseeing and Dining',
      operatorId: 37,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 119.0,
      retailPrice: 119.0,
      levy: 0.0,
      rating: 4.8,
      decorators: [
        {
          id: 1,
          name: 'BEST_SELLER',
          key: 'bestSeller',
          label: 'Best seller',
        },
        {
          id: 5,
          name: 'MEALS_INCLUDED',
          key: 'mealsIncluded',
          label: 'Meals included',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
      ],
      geolocations: [
        {
          latitude: 78.0,
          longitude: 35.0,
          address: 'mretdnpofm, aftxkq, lby 9989',
          label: 'Sea World Cruises ',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/9011/newImage/143903.jpg',
        'https://cdn.experienceoz.com.au/assets/product/9011/newImage/143904.jpg',
        'https://cdn.experienceoz.com.au/assets/product/9011/newImage/143905.jpg',
        'https://cdn.experienceoz.com.au/assets/product/9011/newImage/143906.jpg',
        'https://cdn.experienceoz.com.au/assets/product/9011/newImage/143907.jpg',
      ],
    },
    {
      id: 1072,
      name: 'Whale Watching Cruise from Sea World',
      urlSegment: '10am-whale-watch',
      summary:
        "Join Australia's marine experts for a up to 2.5 hour whale watch cruise and encounter majestic humpback whales aboard a luxurious vessel in the Gold Coast.",
      operatorName: 'Sea World Cruises Whale Watching',
      operatorId: 863,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 89.0,
      retailPrice: 119.0,
      levy: 0.0,
      rating: 4.9,
      hotDeals: [
        {
          productSessionId: 30866,
          message: 'Save 25% per adult on selected departures!',
          expiry: 19661,
        },
        {
          productSessionId: 35005,
          message: 'Save 25% per adult on selected departures!',
          expiry: 19661,
        },
      ],
      decorators: [
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 15,
          name: 'LOW_ADRENALINE_LEVEL',
          key: 'lowAdrenalineLevel',
          label: 'Intensity level low',
        },
      ],
      geolocations: [
        {
          latitude: 14.0,
          longitude: 85.0,
          address: 'rnosrzfygh, aftxkq, lby 9989',
          label: 'Sea World Cruises Whale Watching',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/1072/newImage/138330.jpg',
        'https://cdn.experienceoz.com.au/assets/product/1072/newImage/138814.jpg',
        'https://cdn.experienceoz.com.au/assets/product/1072/newImage/146184.jpg',
        'https://cdn.experienceoz.com.au/assets/product/1072/newImage/138816.jpg',
        'https://cdn.experienceoz.com.au/assets/product/1072/newImage/138817.jpg',
        'https://cdn.experienceoz.com.au/assets/product/1072/newImage/138818.jpg',
        'https://cdn.experienceoz.com.au/assets/product/1072/newImage/138819.jpg',
      ],
    },
    {
      id: 15157,
      name: '3 Day Theme Park Pass + Hopo All Day Ferry Pass',
      urlSegment: 'village-roadshow-three-day-pass-and-hopo-day-pass',
      summary:
        "3 days entry to Warner Bros. Movie World, Sea World & Wet'n'Wild over 5 consecutive days from first visit + Hopo all day ferry pass valued at $30!",
      operatorName: 'Village Roadshow Escape Pass + Hopo All Day Ferry Pass',
      operatorId: 9497,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 195.0,
      retailPrice: 219.0,
      rating: 4.7,
      decorators: [
        {
          id: 1,
          name: 'BEST_SELLER',
          key: 'bestSeller',
          label: 'Best seller',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 15,
          name: 'LOW_ADRENALINE_LEVEL',
          key: 'lowAdrenalineLevel',
          label: 'Intensity level low',
        },
        {
          id: 16,
          name: 'MEDIUM_ADRENALINE_LEVEL',
          key: 'mediumAdrenalineLevel',
          label: 'Intensity level moderate',
        },
        {
          id: 17,
          name: 'HIGH_ADRENALINE_LEVEL',
          key: 'highAdrenalineLevel',
          label: 'Intensity level high',
        },
        {
          id: 25,
          name: 'WHEELCHAIR_FRIENDLY',
          key: 'wheelchairFriendly',
          label: 'Wheelchair friendly',
        },
      ],
      geolocations: [
        {
          latitude: 48.0,
          longitude: 81.0,
          address: 'twabzcehnr, srznmp, lby 1273',
          label: 'Village Roadshow Theme Parks',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/15157/newImage/122676.jpg',
        'https://cdn.experienceoz.com.au/assets/product/15157/newImage/134292.jpg',
        'https://cdn.experienceoz.com.au/assets/product/15157/newImage/134293.jpg',
        'https://cdn.experienceoz.com.au/assets/product/15157/newImage/134294.jpg',
        'https://cdn.experienceoz.com.au/assets/product/15157/newImage/134295.jpg',
        'https://cdn.experienceoz.com.au/assets/product/15157/newImage/134296.jpg',
      ],
    },
    {
      id: 25040,
      name: 'Gold Coast Hot Air Balloon Flight and BONUS SkyPoint Observation Deck Breakfast',
      urlSegment: 'hot-air-ballooning-gold-coast-and-skypoint-breakfast-package',
      summary:
        'Kick start the day with a breathtaking Gold Coast hot air balloon flight and enjoy a FREE delicious breakfast at the SkyPoint Observation Deck any day.',
      operatorName: 'Go Ballooning Gold Coast and SkyPoint Breakfast',
      operatorId: 14212,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 349.0,
      retailPrice: 389.0,
      hotDeals: [
        {
          productSessionId: 42577,
          message: 'FREE breakfast at SkyPoint Observation Deck',
          expiry: 19753,
        },
      ],
      decorators: [
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 16,
          name: 'MEDIUM_ADRENALINE_LEVEL',
          key: 'mediumAdrenalineLevel',
          label: 'Intensity level moderate',
        },
      ],
      geolocations: [
        {
          latitude: 37.0,
          longitude: 14.0,
          address: 'pmyltwpnmb, aftxkq, lby 9989',
          label: 'Go Ballooning Gold Coast',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/25040/newImage/142518.jpg',
        'https://cdn.experienceoz.com.au/assets/product/25040/newImage/142767.jpg',
        'https://cdn.experienceoz.com.au/assets/product/25040/newImage/142517.jpg',
        'https://cdn.experienceoz.com.au/assets/product/25040/newImage/142521.jpg',
        'https://cdn.experienceoz.com.au/assets/product/25040/newImage/142519.jpg',
        'https://cdn.experienceoz.com.au/assets/product/25040/newImage/142520.jpg',
      ],
    },
    {
      id: 13405,
      name: 'Gold Coast Express Jetboat Ride from Main Beach',
      urlSegment: 'broadwater-adventure-express-jet-boating-ride',
      summary:
        'Book a 30 minute express jet boat tour on the Gold Coast and enjoy some fun, high speed thrills along with a dose of lovely scenery on the Broadwater!',
      operatorName: 'Paradise Jet Boating',
      operatorId: 281,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 42.0,
      retailPrice: 60.0,
      levy: 0.0,
      rating: 5.0,
      decorators: [
        {
          id: 1,
          name: 'BEST_SELLER',
          key: 'bestSeller',
          label: 'Best seller',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 17,
          name: 'HIGH_ADRENALINE_LEVEL',
          key: 'highAdrenalineLevel',
          label: 'Intensity level high',
        },
      ],
      geolocations: [
        {
          latitude: 21.0,
          longitude: 48.0,
          address: 'jbjkatkshz, aftxkq, lby 9989',
          label: 'Paradise Jetboating',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/13405/newImage/112812.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13405/newImage/104600.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13405/newImage/112092.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13405/newImage/112809.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13405/newImage/112810.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13405/newImage/112811.jpg',
        'https://cdn.experienceoz.com.au/assets/product/13405/newImage/112813.jpg',
      ],
    },
    {
      id: 5468,
      name: 'Gold Coast Buffet Lunch Cruise',
      urlSegment: 'see-world-buffet-lunch-sightseeing-cruise',
      summary:
        "Hop on board for a great value 2 hour lunch cruise of the Gold Coast that includes a delicious lunch with the city's longest-running cruise company.",
      operatorName: 'Sea World Cruises Sightseeing and Dining',
      operatorId: 37,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 89.0,
      retailPrice: 89.0,
      levy: 0.0,
      rating: 4.7,
      decorators: [
        {
          id: 1,
          name: 'BEST_SELLER',
          key: 'bestSeller',
          label: 'Best seller',
        },
        {
          id: 5,
          name: 'MEALS_INCLUDED',
          key: 'mealsIncluded',
          label: 'Meals included',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
      ],
      geolocations: [
        {
          latitude: 26.0,
          longitude: 56.0,
          address: 'fsbkolezlv, xvjnpj, lby 9989',
          label: 'Sea World Cruises Sightseeing and Dining',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/5468/newImage/143908.jpg',
        'https://cdn.experienceoz.com.au/assets/product/5468/newImage/143909.jpg',
        'https://cdn.experienceoz.com.au/assets/product/5468/newImage/143910.jpg',
        'https://cdn.experienceoz.com.au/assets/product/5468/newImage/143911.jpg',
        'https://cdn.experienceoz.com.au/assets/product/5468/newImage/143912.jpg',
      ],
    },
    {
      id: 11543,
      name: 'SkyPoint Observation Deck and Dine Combo Ticket',
      urlSegment: 'skypoint-observation-deck-and-dine-combo',
      summary:
        "What's better than a meal with a view? This great value combo includes entry to SkyPoint Observation Deck and a dining voucher up to the value of $20!",
      operatorName: 'SkyPoint Observation Deck',
      operatorId: 414,
      region: 'Gold Coast',
      canonicalRegionUrlSegment: 'gold-coast',
      isLive: true,
      fromPrice: 45.0,
      retailPrice: 45.0,
      levy: 0.0,
      rating: 4.6,
      decorators: [
        {
          id: 5,
          name: 'MEALS_INCLUDED',
          key: 'mealsIncluded',
          label: 'Meals included',
        },
        {
          id: 12,
          name: 'LOW_FITNESS_LEVEL',
          key: 'lowFitnessLevel',
          label: 'Fitness level low',
        },
        {
          id: 15,
          name: 'LOW_ADRENALINE_LEVEL',
          key: 'lowAdrenalineLevel',
          label: 'Intensity level low',
        },
        {
          id: 25,
          name: 'WHEELCHAIR_FRIENDLY',
          key: 'wheelchairFriendly',
          label: 'Wheelchair friendly',
        },
      ],
      geolocations: [
        {
          latitude: 43.0,
          longitude: 33.0,
          address: 'civksvovey, xvjnpj, lby 9989',
          label: 'SkyPoint Observation Deck',
        },
      ],
      images: [
        'https://cdn.experienceoz.com.au/assets/product/11543/newImage/115762.jpg',
        'https://cdn.experienceoz.com.au/assets/product/11543/newImage/115764.jpg',
        'https://cdn.experienceoz.com.au/assets/product/11543/newImage/115765.jpg',
        'https://cdn.experienceoz.com.au/assets/product/11543/newImage/115766.jpg',
        'https://cdn.experienceoz.com.au/assets/product/11543/newImage/115767.jpg',
        'https://cdn.experienceoz.com.au/assets/product/11543/newImage/115768.jpg',
      ],
    },
  ],
}

export const orderResponse: OrderHistoryResponse = {
  ids: [11242],
  purchases: [
    {
      id: 12345,
      purchaseDate: '2023-05-09 10:41',
      vouchers: [
        {
          id: 1111,
          number: 1800000000,
          operatorId: 3213,
          operatorName: 'Currumbin Wildlife Sanctuary',
          productId: 5543,
          productName: 'Currumbin Wildlife Sanctuary Entry Tickets',
        },
        {
          id: 1234,
          number: 1800000001,
          operatorId: 4444,
          operatorName: 'SEA Life Sunshine Coast',
          productId: 2342,
          productName: 'SEA Life Sunshine Coast Entry Tickets',
          bookingDate: '28/02/2025',
        },
      ],
      downloadLink: 'https://google.com',
    },
  ],
}

export const blogHero: BlogHero = {
  title: 'What To Expect: A Beginners Guide To Skydiving',
  image: 'https://cdn.experienceoz.com.au/assets/product/2025/newImage/84494.jpg',
  type: 'imageBackground',
  label: 'Guide',
  link: 'https://cdn.experienceoz.com.au',
}

export const checkoutConfiguration: CheckoutConfiguration = {
  extraFields: [
    // COMMENT/UNCOMMENT THIS TO TEST EXTRA FIELD BEHAVIOUR ON CART
    /*{
      label: 'Member number',
      fieldName: 'membershipId',
      inputType: 'text',
      required: true,
      length: 15,
      maxLength: 20,
    },*/
  ],
  paymentOptions: [
    {
      paymentType: 'CARD_WEB',
      main: true,
    },
  ],
}

export const getCartResponse: GetCartResponse = {
  ...defaultPageProperties,
  checkoutConfiguration,
  error: false,
  currency: 'AUD',
  minimalNavigation: true,
}

export const cartField: ValidatedCartField = {
  fieldName: 'membershipId',
  successful: true,
  errorMessage: 'Invalid member number',
}
export const cartFields: ValidatedCartFields = {
  validateFields: [cartField],
}

export const cartValidateResponse: CartValidateResponse = {
  cartFields,
}

export const createCartSuccessResponse: CartResponse = {
  status: 'success',
  data: {
    id: 'c8037e5a-9f62-4542-ac1b-3eaffaab37f2',
    uuid: 'w7fiybt34wo8ihtvg4ocrhtg4bnoru39b4t',
    subTotal: 0.0,
    total: 0.0,
  },
}

export const addCartItemsSuccessResponse: AddCartResponse = {
  status: 'success',
  data: {
    id: 6567,
    uuid: 'c52789dd-395a-40ed-a42e-bdd6d95a11d7',
    total: 86.0,
    subTotal: 86.0,
    bookingDate: '2023-10-26',
    options: [
      {
        id: 99951,
        quantity: 1,
        unitPrice: 86.0,
      },
    ],
  },
}

export const getCartSuccessResponse: CartResponse = {
  status: 'success',
  data: {
    id: 'c8037e5a-9f62-4542-ac1b-3eaffaab37f2',
    uuid: 'w7fiybt34wo8ihtvg4ocrhtg4bnoru39b4t',
    items: [
      {
        id: 6570,
        uuid: '68f0a126-1627-47bb-afda-c9d86d668f56',
        total: 86.0,
        subTotal: 86.0,
        bookingDate: '2023-10-26',
        metadata: {
          catalogueInfo: {
            productName: 'Currumbin Wildlife Sanctuary Tickets',
            regionUrlSegment: 'gold-coast',
            productBannerUrl: 'https://cdn.experienceoz.com.au/assets/product/129/newImage/113793.jpg',
            productUrlSegment: 'currumbin-wildlife-sanctuary-general-admission',
          },
        },
        options: [
          {
            id: 99954,
            quantity: 1,
            unitPrice: 86.0,
            metadata: {
              productOptionName: 'Adult',
              productSessionName: 'General Admission',
            },
          },
        ],
      },
      {
        id: 5848,
        uuid: 'b0fa69a2-b25b-4f6f-914c-af7e3e2bcbe1',
        total: 226.0,
        subTotal: 226.0,
        bookingDate: '2023-07-29',
        options: [
          {
            id: 120909,
            quantity: 1,
            unitPrice: 226.0,
            metadata: {
              productOptionName: 'Adult',
              productSessionName: 'Kuranda Scenic Railway, Skyrail and Hartleys Tour',
            },
          },
        ],
        metadata: {
          pickup: 'Grosvenor In Cairns - Grove St near Cnr of McLeod St on 29-JUL-2023 @ 07:40',
          catalogueInfo: {
            isGift: false,
            operatorId: 5085,
            productName: 'Kuranda Skyrail, Scenic Railway and Hartleys Crocodile Adventures Tour',
            productBannerUrl: 'https://cdn.experienceoz.com.au/assets/product/5848/newImage/123641.jpg',
            bookingRequired: true,
            bookingNotesRequired: false,
            regionUrlSegment: 'cairns',
            productUrlSegment: 'kuranda-skyrail-and-scenic-railway-with-hartleys-crocodile-adventure-park-day-tour',
            regionId: 10,
            operatorName: 'Kuranda Day Tour including Scenic Rail Up, Skyrail Down and Hartleys Crocodile Adventures',
            productFromPrice: 226,
            productRetailPrice: 226,
          },
        },
        pickup: {
          generalLocation: 'Cairns',
          type: 'PRECONFIGURED',
          value: 'Acacia Court Hotel',
          key: 'ACACIA COURT HOTEL',
          info: 'Cairns-Acacia Court Hotel',
        },
      },
    ],
    purchaser: {
      firstName: 'Test',
      lastName: 'Tester',
      email: 'test@experienceoz.com.au',
      countryCode: 'AU',
      suburb: 'WESTMEAD',
      postcode: '2145',
      phoneNumber: {
        code: '+61',
        number: '0413932478',
      },
    },
    subTotal: 344.0,
    coupon: {
      code: 'ghtfcapshfrbndt',
      discount: 17.2,
      email: 'test@experienceoz.com.au',
      reservationId: 'null',
      reservationExpiry: 'null',
    },
    total: 326.8,
  },
}

export const addCouponErrorResponse: PromoCodeResponse = {
  status: 'error',
  errors: [
    {
      code: 'cart.coupon.exists',
      message: 'A coupon has already been applied to the cart.',
    },
  ],
}
export const addCouponSuccessResponse: PromoCodeResponse = {
  status: 'success',
  data: {
    code: 'ghtfcapshfrbndt',
    discount: 8.6,
    email: 'test@experienceoz.com.au',
  },
}

//TODO add complete mock response and update types as needed
export const checkoutCartSuccessResponse: CheckoutCartResponse = {
  status: 'success',
  data: { id: 546378 },
}
export const checkoutCartErrorResponse: CheckoutCartResponse = {
  status: 'error',
}

//TODO add complete mock response and update types as needed
export const purchaseLookupSuccessResponse: PurchaseLookupResponse = {
  status: 'success',
  data: {
    id: 5595546,
    purchaser: {
      firstName: 't',
      lastName: 't',
      email: 't@t.com',
      phoneNumber: '+61469889322',
    },
    total: 557.95,
    vouchers: [
      {
        id: 5595557,
        bookingDate: '10/10/10',
        bookingConfirmation: {
          status: 'CONFIRMED',
        },
        soldPrice: 59.95,
        product: {
          id: 14312,
          name: 'Gift Voucher',
          image: 'https://cdn.experienceoz.com.au/assets/product/14312/newImage/100254.jpg',
          cancellationPolicy: 'test',
        },
        status: 'FINALISED',
        links: [
          {
            rel: 'cancel',
            href: '/purchases/5595546/vouchers/5595557',
          },
          {
            rel: 'pdf',
            href: 'http%3A%2F%2Flocalhost%3A8086%2Fpdf%2F5595546%2F217196110%2Fecb95b1b4b2ccf90c46850e1ea027a6694b22564',
          },
          {
            rel: 'html',
            href: 'http%3A%2F%2Flocalhost%3A8086%2Fvoucher%2F5595546%2F217196110%2Fecb95b1b4b2ccf90c46850e1ea027a6694b22564',
          },
        ],
      },
      {
        id: 5595558,
        bookingConfirmation: {
          status: 'CONFIRMED',
        },
        soldPrice: 498.0,
        bookingDate: '2023-09-29',
        options: [
          {
            name: 'Per Person',
            quantity: 2,
            unitPrice: 249.0,
            sessionId: 17369,
            sessionName: '11.30am 40 Minute Reef Hopper Scenic Flight',
            sessionTime: '11:30',
          },
        ],
        product: {
          id: 8282,
          name: 'Great Barrier Reef 40 Minute Scenic Flight',
          image: 'https://cdn.experienceoz.com.au/assets/product/8282/newImage/112473.jpg',
          cancellationPolicy:
            'You MUST CALL the supplier before proceeding with cancelling this booking. 100% cancellation fee applies within 48hrs of tour date',
        },
        status: 'FINALISED',
        links: [
          {
            rel: 'cancel',
            href: '/purchases/5595546/vouchers/5595558',
          },
          {
            rel: 'pdf',
            href: 'http%3A%2F%2Flocalhost%3A8086%2Fpdf%2F5595546%2F217196111%2Fecb95b1b4b2ccf90c46850e1ea027a6694b22564',
          },
          {
            rel: 'html',
            href: 'http%3A%2F%2Flocalhost%3A8086%2Fvoucher%2F5595546%2F217196111%2Fecb95b1b4b2ccf90c46850e1ea027a6694b22564',
          },
        ],
      },
      {
        id: 5595558,
        bookingConfirmation: {
          status: 'CONFIRMED',
        },
        soldPrice: 498.0,
        bookingDate: '2023-09-29',
        options: [
          {
            name: 'Per Person',
            quantity: 2,
            unitPrice: 249.0,
            sessionId: 17369,
            sessionName: '11.30am 40 Minute Reef Hopper Scenic Flight',
            sessionTime: '11:30',
          },
        ],
        product: {
          id: 8282,
          name: 'Great Barrier Reef 40 Minute Scenic Flight',
          image: 'https://cdn.experienceoz.com.au/assets/product/8282/newImage/112473.jpg',
          cancellationPolicy:
            'You MUST CALL the supplier before proceeding with cancelling this booking. 100% cancellation fee applies within 48hrs of tour date',
        },
        status: 'FINALISED',
        links: [
          {
            rel: 'cancel',
            href: '/purchases/5595546/vouchers/5595558',
          },
          {
            rel: 'pdf',
            href: 'http%3A%2F%2Flocalhost%3A8086%2Fpdf%2F5595546%2F217196111%2Fecb95b1b4b2ccf90c46850e1ea027a6694b22564',
          },
          {
            rel: 'html',
            href: 'http%3A%2F%2Flocalhost%3A8086%2Fvoucher%2F5595546%2F217196111%2Fecb95b1b4b2ccf90c46850e1ea027a6694b22564',
          },
        ],
      },
    ],

    links: [
      {
        rel: 'self',
        href: '/purchases/5595546',
      },
      {
        rel: 'pdf',
        href: 'http%3A%2F%2Flocalhost%3A8086%2Fpdf%2F5595546%2Fecb95b1b4b2ccf90c46850e1ea027a6694b22564',
      },
      {
        rel: 'html',
        href: 'http%3A%2F%2Flocalhost%3A8086%2Fvoucher%2F5595546%2Fecb95b1b4b2ccf90c46850e1ea027a6694b22564',
      },
    ],
  },
}

export const purchaseLookupErrorResponse: PurchaseLookupResponse = {
  status: 'error',
  errors: [],
}

export const productAvailableResponse: ProductAvailabilityResponse = {
  status: 'success',
  data: {
    '2023-07-29': {
      sessions: [
        {
          id: 26196,
          status: 'AVAILABLE',
          totalSellPrice: 0,
          seatsAvailable: 0,
          options: [
            {
              id: 76450,
              price: 149.0,
              retailPrice: 149.0,
              quantity: 2,
            },
            {
              id: 76708,
              price: 99.0,
              retailPrice: 99.0,
              quantity: 1,
            },
          ],
          totalPrice: 397.0,
          totalRetailPrice: 397.0,
        },
        {
          id: 26197,
          status: 'AVAILABLE',
          totalSellPrice: 0,
          seatsAvailable: 0,
          options: [
            {
              id: 76451,
              price: 149.0,
              retailPrice: 149.0,
              quantity: 2,
            },
            {
              id: 76710,
              price: 99.0,
              retailPrice: 99.0,
              quantity: 1,
            },
          ],
          totalPrice: 397.0,
          totalRetailPrice: 397.0,
        },
        {
          id: 26198,
          status: 'AVAILABLE',
          totalSellPrice: 0,
          seatsAvailable: 0,
          options: [
            {
              id: 76452,
              price: 149.0,
              retailPrice: 149.0,
              quantity: 2,
            },
            {
              id: 76712,
              price: 99.0,
              retailPrice: 99.0,
              quantity: 1,
            },
          ],
          totalPrice: 397.0,
          totalRetailPrice: 397.0,
        },
        {
          id: 26199,
          status: 'AVAILABLE',
          totalSellPrice: 0,
          seatsAvailable: 0,
          options: [
            {
              id: 76453,
              price: 149.0,
              retailPrice: 149.0,
              quantity: 2,
            },
            {
              id: 76714,
              price: 99.0,
              retailPrice: 99.0,
              quantity: 1,
            },
          ],
          totalPrice: 397.0,
          totalRetailPrice: 397.0,
        },
        {
          id: 2655,
          status: 'AVAILABLE',
          totalSellPrice: 0,
          seatsAvailable: 0,
          options: [
            {
              id: 106628,
              price: 149.0,
              retailPrice: 149.0,
              quantity: 2,
            },
            {
              id: 106629,
              price: 99.0,
              retailPrice: 99.0,
              quantity: 1,
            },
          ],
          totalPrice: 397.0,
          totalRetailPrice: 397.0,
        },
        {
          id: 26194,
          status: 'AVAILABLE',
          totalSellPrice: 0,
          seatsAvailable: 0,
          options: [
            {
              id: 106631,
              price: 149.0,
              retailPrice: 149.0,
              quantity: 2,
            },
            {
              id: 106632,
              price: 99.0,
              retailPrice: 99.0,
              quantity: 1,
            },
          ],
          totalPrice: 397.0,
          totalRetailPrice: 397.0,
        },
        {
          id: 26195,
          status: 'AVAILABLE',
          totalSellPrice: 0,
          seatsAvailable: 0,
          options: [
            {
              id: 106634,
              price: 149.0,
              retailPrice: 149.0,
              quantity: 2,
            },
            {
              id: 106635,
              price: 99.0,
              retailPrice: 99.0,
              quantity: 1,
            },
          ],
          totalPrice: 397.0,
          totalRetailPrice: 397.0,
        },
      ],
    },
  },
}

export const productAvailableResponse_25040 = {
  status: 'success',
  data: {
    '2023-07-29': {
      sessions: [
        {
          metadata: {
            '764099': '4329841623',
            '1813228': '4329841624',
            availabilityId: '1058337963',
          },
          totalPrice: 349.0,
          totalRetailPrice: 389.0,
          options: [
            {
              quantity: 1,
              price: 349.0,
              id: 119197,
              retailPrice: 389.0,
            },
          ],
          id: 42577,
          status: 'AVAILABLE',
        },
      ],
    },
  },
}

export const productTremsAvailabilityResponse = {
  status: 'success',
  data: {
    '2023-07-29': {
      sessions: [
        {
          metadata: {
            pickup: 'Grosvenor In Cairns - Grove St near Cnr of McLeod St on 29-JUL-2023 @ 07:40',
          },
          totalPrice: 226.0,
          totalRetailPrice: 226.0,
          options: [
            {
              quantity: 1,
              price: 226.0,
              id: 120909,
              retailPrice: 226.0,
            },
          ],
          id: 43269,
          status: 'AVAILABLE',
        },
      ],
    },
  },
}

export const quotes: Blockquote[] = [
  {
    quote: "Obstacles aren't road blocks, they're sign posts. Do what you fear most",
    image: 'https://cdn.experienceoz.com.au/assets/product/5848/newImage/123641.jpg',
  },
  {
    quote: 'Fear has the power to help you do things you never thought were possible',
    image: 'https://cdn.experienceoz.com.au/assets/product/15157/newImage/122676.jpg',
  },
]

export const jwksResponse: Jwks = {
  keys: [
    {
      kty: 'RSA',
      kid: 'brg-sig',
      n: 'ALJfpuyuwuxHHgTb2s-EVX5oyKC-Wt-mFC4-UegKFhuNf1IpWfiS-EKfEymmUrMjqy0jlU2sjk9C4cOfYzmZ6EIdYl-T1Nsmxf6QGUKTu88WafKhIOF7LGvMAGGmIGrOaF1m2RqXPkFCjY3biUzA7hl5rA5P4Vuo_OSlFgRhONAZ',
      e: 'AQAB',
      alg: 'RS256',
      use: 'sig',
    },
    {
      kty: 'RSA',
      kid: 'brg-enc',
      n: 'ALJfpuyuwuxHHgTb2s-EVX5oyKC-Wt-mFC4-UegKFhuNf1IpWfiS-EKfEymmUrMjqy0jlU2sjk9C4cOfYzmZ6EIdYl-T1Nsmxf6QGUKTu88WafKhIOF7LGvMAGGmIGrOaF1m2RqXPkFCjY3biUzA7hl5rA5P4Vuo_OSlFgRhONAZ',
      e: 'AQAB',
      alg: 'RSA-OAEP-256',
      use: 'enc',
    },
  ],
}

export const contentBlockOne: ImageTextLinkBlock = {
  label: 'Q&A',
  heading: 'Can I trust the parachute, for real?',
  description:
    "We're the first to crave a far flung pursuit, but sometimes it can be about simply heading somewhere close by doing something different and slightly out of your comfort zone....",
  link: 'https://google.com',
  image: 'https://cdn.experienceoz.com.au/assets/product/12630/newImage/119711.jpg',
  imageAlignment: 'left',
  inverse: true,
}

export const contentBlockTwo: ImageTextLinkBlock = {
  label: 'Crew stories',
  heading: 'The sky is NOT the limit',
  description:
    "We're the first to crave a far flung pursuit, but sometimes it can be about simply heading somewhere close by doing something different and slightly out of your comfort zone....",
  link: 'https://google.com',
  image: 'https://cdn.experienceoz.com.au/assets/product/4694/newImage/115788.jpg',
  imageAlignment: 'right',
  inverse: true,
}

export const author: BlogAuthor = {
  name: 'Tony Westlake',
  image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154103.png',
  description:
    'The mountains are really my happy place. When I am in the mountains all my other cares and worries seem to not matter and I feel completely relaxed.',
}

export const blogTags: BlogTag[] = [
  {
    text: 'Beginners',
    url: 'https://google.com',
  },
  {
    text: 'Growth',
    url: 'https://google.com',
  },
  {
    text: 'Adventure',
    url: 'https://google.com',
  },
  {
    text: 'Motivating',
    url: 'https://google.com',
  },
]

export const crewStories: CrewStory[] = [
  {
    image: 'https://cdn.experienceoz.com.au/assets/product/12128/newImage/113859.jpg',
    url: 'https://google.com',
    avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154103.png',
    storyTitle: 'Sea Turtle Snorkel with an unexpected surprise',
  },
  {
    image: 'https://cdn.experienceoz.com.au/assets/product/8548/newImage/149616.jpg',
    url: 'https://google.com',
    avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154103.png',
    storyTitle: 'An Ode To Lookouts',
  },
  {
    image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/116229.jpg',
    url: 'https://google.com',
    avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154103.png',
    storyTitle: 'An ultimate Gold Coast bucket list',
  },
]

export const homeEntityDetails: EntityDetails = {
  id: 1,
  name: 'Home',
  urlSegment: '',
  stateUrlSegment: '',
  title: 'Book The Best Adventures & Things To Do',
  heading: '',
  summary: '',
  shortDescription: '',
  description: '',
  geolocation: { latitude: 22.9068, longitude: 43.1729 },
  quantity: 0,
  offset: 0,
  products: [],
}

export const homeResponse: HomeResponse = {
  ...defaultPageProperties,
  entityDetails: regionEntityDetails,
  templateDefinition: {
    widgets: [
      {
        name: 'navigation-bar',
      },
      {
        name: 'home-banner-minimal',
        title: 'Confidence Is Earned',
        subtitle: 'Book the best adventures & things to do with Adrenaline',
        banners: [
          {
            heading: 'New Site, New Adventures',
            cta: 'Explore',
            image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/192521.jpg',
            link: 'https://www.experienceoz.com.au/en/adventures/school-holidays',
          },
          {
            heading: 'Family fun days',
            cta: 'Book now',
            image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/160571.jpg',
            link: 'https://www.experienceoz.com.au/en/adventures/with-kids',
          },
        ],
      },
      {
        name: 'featured-product-tabs',
        title: 'Top locations',
        tabs: [
          {
            label: 'Gold Coast',
            products: [
              {
                name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
                supplierName: 'Jetboat Extreme',
                urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
                fromPrice: 85.0,
                retailPrice: 89.0,
                levy: 0.0,
                rating: 4.9,
                hotDealMessage: '',
                imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
                decorators: [
                  {
                    id: 1,
                    name: 'BEST_SELLER',
                    key: 'bestSeller',
                    label: 'Best seller',
                  },
                  {
                    id: 17,
                    name: 'HIGH_ADRENALINE_LEVEL',
                    key: 'highAdrenalineLevel',
                    label: 'Intensity level high',
                  },
                ],
              },
            ],
            cta: 'All Gold Coast Experiences',
            link: 'https://adrenaline.bigredgroup-dev.net/en/gold-coast',
          },
          {
            label: 'Sydney',
            products: [
              {
                name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
                supplierName: 'Jetboat Extreme',
                urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
                hotDealMessage: '',
                fromPrice: 85.0,
                retailPrice: 89.0,
                levy: 0.0,
                rating: 4.9,
                imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
                decorators: [
                  {
                    id: 1,
                    name: 'BEST_SELLER',
                    key: 'bestSeller',
                    label: 'Best seller',
                  },
                  {
                    id: 17,
                    name: 'HIGH_ADRENALINE_LEVEL',
                    key: 'highAdrenalineLevel',
                    label: 'Intensity level high',
                  },
                ],
              },
            ],
            cta: 'All Sydney Experiences',
            link: 'https://adrenaline.bigredgroup-dev.net/en/sydney',
          },
          {
            label: 'Brisbane',
            products: [
              {
                name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
                supplierName: 'Jetboat Extreme',
                urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
                fromPrice: 85.0,
                retailPrice: 89.0,
                hotDealMessage: '',
                levy: 0.0,
                rating: 4.9,
                imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
                decorators: [
                  {
                    id: 1,
                    name: 'BEST_SELLER',
                    key: 'bestSeller',
                    label: 'Best seller',
                  },
                  {
                    id: 17,
                    name: 'HIGH_ADRENALINE_LEVEL',
                    key: 'highAdrenalineLevel',
                    label: 'Intensity level high',
                  },
                ],
              },
            ],
            cta: 'All Brisbane Experiences',
            link: 'https://adrenaline.bigredgroup-dev.net/en/brisbane',
          },
          {
            label: 'Adelaide',
            products: [
              {
                name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
                supplierName: 'Jetboat Extreme',
                urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
                fromPrice: 85.0,
                retailPrice: 89.0,
                hotDealMessage: '',
                levy: 0.0,
                rating: 4.9,
                imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
                decorators: [
                  {
                    id: 1,
                    name: 'BEST_SELLER',
                    key: 'bestSeller',
                    label: 'Best seller',
                  },
                  {
                    id: 17,
                    name: 'HIGH_ADRENALINE_LEVEL',
                    key: 'highAdrenalineLevel',
                    label: 'Intensity level high',
                  },
                ],
              },
            ],
            cta: 'All Adelaide Experiences',
            link: 'https://adrenaline.bigredgroup-dev.net/en/adelaide',
          },
          {
            label: 'Melbourne',
            products: [
              {
                name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
                supplierName: 'Jetboat Extreme',
                urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
                fromPrice: 85.0,
                retailPrice: 89.0,
                hotDealMessage: '',
                levy: 0.0,
                rating: 4.9,
                imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
                decorators: [
                  {
                    id: 1,
                    name: 'BEST_SELLER',
                    key: 'bestSeller',
                    label: 'Best seller',
                  },
                  {
                    id: 17,
                    name: 'HIGH_ADRENALINE_LEVEL',
                    key: 'highAdrenalineLevel',
                    label: 'Intensity level high',
                  },
                ],
              },
            ],
            cta: 'All Melbourne Experiences',
            link: 'https://adrenaline.bigredgroup-dev.net/en/melbourne',
          },
          {
            label: 'Perth',
            products: [
              {
                name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
                supplierName: 'Jetboat Extreme',
                urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
                fromPrice: 85.0,
                retailPrice: 89.0,
                hotDealMessage: '',
                levy: 0.0,
                rating: 4.9,
                imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
                decorators: [
                  {
                    id: 1,
                    name: 'BEST_SELLER',
                    key: 'bestSeller',
                    label: 'Best seller',
                  },
                  {
                    id: 17,
                    name: 'HIGH_ADRENALINE_LEVEL',
                    key: 'highAdrenalineLevel',
                    label: 'Intensity level high',
                  },
                ],
              },
            ],
            cta: 'All Perth Experiences',
            link: 'https://adrenaline.bigredgroup-dev.net/en/perth',
          },
        ],
      },
      {
        name: 'category-grid',
        label: 'Top categories',
        categories: [
          {
            name: 'Attractions',
            link: 'attractions',
            image: 'https://cdn.experienceoz.com.au/assets/productCategory/1131/newBanner/163136.jpg',
          },
          {
            name: 'Cruises',
            link: 'cruises',
            image: 'https://cdn.experienceoz.com.au/assets/productCategory/1133/newBanner/163138.jpg',
          },
          {
            name: 'Activities',
            link: 'activities',
            image: 'https://cdn.experienceoz.com.au/assets/productCategory/1134/newBanner/163137.jpg',
          },
          {
            name: 'Water Sports',
            link: 'water-sports',
            image: 'https://cdn.experienceoz.com.au/assets/productCategory/1135/newBanner/192825.jpg',
          },
        ],
      },
      {
        name: 'cta-promo-banner',
        title: '<span>FEEL ADVENTURE</span>',
        cta: 'Join now',
        link: 'https://adrenaline.bigredgroup-dev.net/en/signup',
        image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152996.jpg',
      },
      {
        name: 'static-product-carousel',
        label: 'On Sale',
        entity: '',
        products: [
          {
            name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
            supplierName: 'Jetboat Extreme',
            urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
            fromPrice: 85.0,
            retailPrice: 89.0,
            hotDealMessage: '',
            levy: 0.0,
            rating: 4.9,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
              {
                id: 17,
                name: 'HIGH_ADRENALINE_LEVEL',
                key: 'highAdrenalineLevel',
                label: 'Intensity level high',
              },
            ],
          },
        ],
      },
      {
        name: 'static-product-carousel',
        label: 'Highest rated',
        entity: '',
        products: [
          {
            name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
            supplierName: 'Jetboat Extreme',
            urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
            fromPrice: 85.0,
            retailPrice: 89.0,
            hotDealMessage: '',
            levy: 0.0,
            rating: 4.9,
            imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
            decorators: [
              {
                id: 1,
                name: 'BEST_SELLER',
                key: 'bestSeller',
                label: 'Best seller',
              },
              {
                id: 17,
                name: 'HIGH_ADRENALINE_LEVEL',
                key: 'highAdrenalineLevel',
                label: 'Intensity level high',
              },
            ],
          },
        ],
      },
      {
        name: 'campaign-promo',
        title: 'F*ck cake, give <span>ADVENTURE</span>',
        description: 'Shop gift vouchers',
        link: 'https://adrenaline.bigredgroup-dev.net/en/gift-vouchers',
        image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152997.jpg',
      },
      {
        name: 'featured-product-tabs',
        title: "Toni's favourite adventures",
        tabs: [
          {
            label: 'Driving experiences',
            products: [
              {
                name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
                supplierName: 'Jetboat Extreme',
                urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
                fromPrice: 85.0,
                retailPrice: 89.0,
                hotDealMessage: '',
                levy: 0.0,
                rating: 4.9,
                imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
                decorators: [
                  {
                    id: 1,
                    name: 'BEST_SELLER',
                    key: 'bestSeller',
                    label: 'Best seller',
                  },
                  {
                    id: 17,
                    name: 'HIGH_ADRENALINE_LEVEL',
                    key: 'highAdrenalineLevel',
                    label: 'Intensity level high',
                  },
                ],
              },
            ],
            cta: 'See all driving experiences',
            link: 'https://adrenaline.bigredgroup-dev.net/en/gold-coast',
          },
          {
            label: 'Skydiving experiences',
            products: [
              {
                name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
                supplierName: 'Jetboat Extreme',
                urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
                fromPrice: 85.0,
                retailPrice: 89.0,
                levy: 0.0,
                hotDealMessage: '',
                rating: 4.9,
                imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
                decorators: [
                  {
                    id: 1,
                    name: 'BEST_SELLER',
                    key: 'bestSeller',
                    label: 'Best seller',
                  },
                  {
                    id: 17,
                    name: 'HIGH_ADRENALINE_LEVEL',
                    key: 'highAdrenalineLevel',
                    label: 'Intensity level high',
                  },
                ],
              },
            ],
            cta: 'See all Skydiving experiences',
            link: 'https://adrenaline.bigredgroup-dev.net/en/gold-coast',
          },
          {
            label: 'Nature experiences',
            products: [
              {
                name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
                supplierName: 'Jetboat Extreme',
                urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
                fromPrice: 85.0,
                retailPrice: 89.0,
                levy: 0.0,
                rating: 4.9,
                hotDealMessage: '',
                imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
                decorators: [
                  {
                    id: 1,
                    name: 'BEST_SELLER',
                    key: 'bestSeller',
                    label: 'Best seller',
                  },
                  {
                    id: 17,
                    name: 'HIGH_ADRENALINE_LEVEL',
                    key: 'highAdrenalineLevel',
                    label: 'Intensity level high',
                  },
                ],
              },
            ],
            cta: 'See all Nature experiences',
            link: 'https://adrenaline.bigredgroup-dev.net/en/gold-coast',
          },
          {
            label: 'Watersports',
            products: [
              {
                name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
                supplierName: 'Jetboat Extreme',
                urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
                fromPrice: 85.0,
                retailPrice: 89.0,
                hotDealMessage: '',
                levy: 0.0,
                rating: 4.9,
                imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
                decorators: [
                  {
                    id: 1,
                    name: 'BEST_SELLER',
                    key: 'bestSeller',
                    label: 'Best seller',
                  },
                  {
                    id: 17,
                    name: 'HIGH_ADRENALINE_LEVEL',
                    key: 'highAdrenalineLevel',
                    label: 'Intensity level high',
                  },
                ],
              },
            ],
            cta: 'See all Watersport experiences',
            link: 'https://adrenaline.bigredgroup-dev.net/en/gold-coast',
          },
          {
            label: 'Ballooning',
            products: [
              {
                name: 'Jet Boat Ride, 55 Minutes - Central Surfers Paradise, Gold Coast',
                supplierName: 'Jetboat Extreme',
                urlPath: '/gold-coast/jet-boating-from-surfers-paradise',
                fromPrice: 85.0,
                retailPrice: 89.0,
                hotDealMessage: '',
                levy: 0.0,
                rating: 4.9,
                imageUrl: 'https://cdn.experienceoz.com.au/assets/product/476/newImage/157746.jpg',
                decorators: [
                  {
                    id: 1,
                    name: 'BEST_SELLER',
                    key: 'bestSeller',
                    label: 'Best seller',
                  },
                  {
                    id: 17,
                    name: 'HIGH_ADRENALINE_LEVEL',
                    key: 'highAdrenalineLevel',
                    label: 'Intensity level high',
                  },
                ],
              },
            ],
            cta: 'See all Ballooning experiences',
            link: 'https://adrenaline.bigredgroup-dev.net/en/gold-coast',
          },
        ],
      },
      {
        name: 'blog-pages-carousel',
        blogPages: [
          {
            title: 'The Best Places To Go Horse Riding In Australia',
            link: 'the-best-places-to-go-horse-riding-in-australia',
            image: 'https://cdn.experienceoz.com.au/assets/contentPage/1642/image/163633.jpg',
          },
          {
            title: 'Top Adventures Under $200',
            link: 'top-adventures-under-200',
            image: 'https://cdn.experienceoz.com.au/assets/contentPage/1632/image/162906.jpg',
          },
          {
            title: '10 Things To Do On A Budget in Sydney',
            link: '10-things-to-do-on-a-budget-in-sydney',
            image: 'https://cdn.experienceoz.com.au/assets/contentPage/1633/image/163191.jpg',
          },
        ],
        variant: 'recent',
      },
      {
        name: 'footer',
      },
      contentContributorShowcase,
    ],
  },

  entityType: 'HOME',
  error: false,
}

export const crewListingResponse: CrewListingResponse = {
  ...defaultPageProperties,
  crewListings: [
    {
      name: 'Alex Kydd',
      title: 'Back to nature',
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/153959.jpg',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154097.png',
      urlSegment: '/crew/1',
    },
    {
      name: 'Em Hanney',
      title: 'Ocean adventurer',
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/113261.jpg',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154098.png',
      urlSegment: '/crew/2',
    },
    {
      name: 'Jacqui Bell',
      title: 'Jump junkie',
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152074.jpg',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154099.png',
      urlSegment: '/crew/3',
    },
    {
      name: 'Max Horner',
      title: 'Try anything once',
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/145217.jpg',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154100.png',
      urlSegment: '/crew/4',
    },
    {
      name: 'Maddy Rose',
      title: 'Water lover',
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/144564.jpg',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154101.png',
      urlSegment: '/crew/5',
    },
    {
      name: 'Josh Crane',
      title: 'Full time explorer',
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152997.jpg',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154102.png',
      urlSegment: '/crew/6',
    },
    {
      name: 'Dylan Maddock',
      title: 'Snow angel',
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/113285.jpg',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154103.png',
      urlSegment: '/crew/7',
    },
    {
      name: 'Josh Hickie',
      title: 'Wave rider',
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152995.jpg',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154104.png',
      urlSegment: '/crew/8',
    },
    {
      name: 'Toni Westlake',
      title: 'Sunset chaser',
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/155003.jpg',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154105.png',
      urlSegment: '/crew/9',
    },
    {
      name: 'Matt Smith',
      title: 'Thrill seeker',
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/156190.jpg',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154106.png',
      urlSegment: '/crew/10',
    },
    {
      name: 'Alexis Cray',
      title: 'Road tripper',
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152394.jpg',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152702.png',
      urlSegment: '/crew/11',
    },
    {
      name: 'Jeremy Smith',
      title: 'Part time flyer',
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152071.jpg',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/152921.png',
      urlSegment: '/crew/12',
    },
  ],
  crewStories: [
    {
      image: 'https://cdn.experienceoz.com.au/assets/product/12128/newImage/113859.jpg',
      url: 'https://google.com',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154103.png',
      storyTitle: 'Sea Turtle Snorkel with an unexpected surprise',
    },
    {
      image: 'https://cdn.experienceoz.com.au/assets/product/8548/newImage/149616.jpg',
      url: 'https://google.com',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154103.png',
      storyTitle: 'An Ode To Lookouts',
    },
    {
      image: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/116229.jpg',
      url: 'https://google.com',
      avatar: 'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/154103.png',
      storyTitle: 'An ultimate Gold Coast bucket list',
    },
  ],
  error: false,
}

export const adrenalineGiftVoucher: GiftVoucher = {
  id: 1,
  catalogueType: 'ADR',
  allowsCustomAmount: true,
  currencyCode: 'AUD',
  maxAllowedAmount: 40000,
  predefinedAmounts: [100, 150, 250, 500],
  themes: [
    'ADR_FLYING',
    'ADR_JETBOAT',
    'ADR_BUGGY',
    'ADR_PARACHUTE',
    'ADR_HAPPY_ANNIVERSARY',
    'ADR_CONGRATULATIONS',
    'ADR_HAPPY_BIRTHDAY',
  ],
  fields: [
    {
      key: 'TO',
      name: 'gift_to',
      title: 'To',
      placeholder: 'e.g. Mum & Dad.',
      type: 'TEXT',
      required: true,
      minLength: 3,
      maxLength: 200,
      order: 0,
    },
    {
      key: 'MESSAGE',
      name: 'gift_message',
      title: 'Message',
      placeholder: 'e.g. Happy Birthday mate!',
      type: 'LONGTEXT',
      required: true,
      minLength: 3,
      maxLength: 150,
      order: 1,
    },
    {
      key: 'FROM',
      name: 'gift_from',
      title: 'From',
      placeholder: 'e.g. Bridget',
      type: 'TEXT',
      required: true,
      minLength: 3,
      maxLength: 200,
      order: 2,
    },
  ],
  deliveryMethods: [
    {
      key: 'ELECTRONIC',
      label: 'Electronic',
      requiresPhysicalAddress: false,
      amount: 0,
      currencyCode: 'AUD',
      default: true,
    },
    {
      key: 'ADR_STANDARD_POST',
      label: 'Standard Post',
      requiresPhysicalAddress: true,
      amount: 600,
      currencyCode: 'AUD',
      default: false,
    },
    {
      key: 'ADR_EXPRESS_POST',
      label: 'Express Post',
      requiresPhysicalAddress: true,
      amount: 1500,
      currencyCode: 'AUD',
      default: false,
    },
  ],
  lastUpdated: '2024-03-13T18:48:00',
}

export const faqContent: FaqContent = [
  {
    sectionHeading: 'Booking an adventure',
    content: [
      {
        heading: "Why can't I use my promo code?",
        content:
          'Adrenaline promo codes are limited to the purchase of new bookings and vouchers. Promo codes cannot be used when redeeming gift card or gift vouchers. One promotional code per person per transaction can be used. Make sure you have read the promo code terms & conditions for minimum order value and expiry date.',
      },
      {
        heading: 'How do I book an adventure using a voucher?',
        content:
          'To get started on your next adventure, just head to <a href="/en">Adrenaline</a> and follow the below steps: <ol><li>Click \'use your voucher\' in the top menu</li><li>Enter your voucher number (EG. ABC-123-XXX)</li><li>Follow the prompts to complete your order, or to add your voucher to your account.</li></ol>Please note that adventure vouchers over 18 months old will revert to Flexi Vouchers and display a dollar amount.',
      },
      {
        heading: 'Are the prices on Adrenaline per person?',
        content: 'Yes, unless otherwise specified on the description of the experience.',
      },
      {
        heading: 'How can I find my booking details?',
        content:
          '<p>Your booking details can be accessed at any time via My Account or can be found on your booking confirmation email. If you have not received your confirmation email then just reach out to our customer experience team.</p><p>Details include where to go, what to bring, and how to contact your Adventure Provider.</p>',
      },
    ],
  },
  {
    sectionHeading: 'Booking Changes',
    content: [
      {
        heading: 'How do I change my booking',
        content:
          "<p>Any changes to confirmed bookings need to be made directly with the Adventure Provider. You can find their details on your booking confirmation email.</p><p>Just remember that once a booking is confirmed, your Adventure Provider's terms & cancellation policy will apply.</p><p><strong>Unconfirmed bookings</strong></p><p>If your booking has not yet been accepted by the Adventure Provider you can contact Adrenaline directly to cancel the order. If you're not sure, check your emails for a booking confirmation.</p>",
      },
      {
        heading: 'How do I change participant names',
        content:
          "<p>If you have already booked your time & date, you can contact the Adventure Provider on your booking confirmation. Based on their terms & conditions, they should be able to update this for you.</p><p>If you have a voucher without a booked date, the vouchers are fully transferrable! Vouchers can be used by anyone, so simply use the voucher at checkout or via the redeem a voucher page.</p><p>If you want to re-gift (we aren't judging) or give a voucher to a new recipient, our friendly Adventure Consultants can happily assist. Just have your voucher number ready when calling.</p>",
      },
      {
        heading: 'Can I cancel my adventure?',
        content:
          "Once a booking is confirmed, the Adventure Provider's cancellation policy applies - you can find this information along with their contact details on your booking confirmation email.",
      },
      {
        heading: 'What if my booking is cancelled or rescheduled',
        content:
          "<p>We're all about great adventures, but sometimes the weather gets in the way.</p><p>Your Adventure Provider will contact you directly to arrange a new date. If you're not able to reschedule  then you can convert the credit into a Flexi Voucher to be used towards any other Adrenaline adventure.</p><p>In the case of multiple cancellations or reschedules, or if the provider is unable to re-book you within a reasonable time frame, just reach out to our friendly Adventure Consultants and we'll see if we can help.</p><p>Any extra fees (ie. postage or transaction) will not be refunded.</p>",
      },
      {
        heading: 'Can I get a refund?',
        content:
          'There are a range of reasons you may be entitled to a refund from Adrenaline.<ul><li>14 Day Change of Mind refunds apply to Adrenaline Adventure Vouchers that have not yet been booked in. These vouchers can be refunded within 14 days of the purchase date.</li><li>14 Day Change of mind does not apply to flexi vouchers, gift cards, physical products or bookings and excludes any delivery or transaction fees paid at the time of purchase.</li><li>If your experience is unable to be booked because it has been unpublished, or cancelled due to weather you can request a refund through our Adventure Consultants</li></ul><p>If you are unsure, please reach out to our adventure consultants.</p>',
      },
    ],
  },
  {
    sectionHeading: 'Purchasing Vouchers',
    content: [
      {
        heading: 'How do Adrenaline vouchers work?',
        content:
          "<p>Did you know, we've extended our voucher and gift card validity to 5 years? Any Adrenaline Voucher purchased on or after 1st December 2017 will have an expiry date of 60 months from the date of purchase.</p> <p><strong>Adventure Vouchers</strong></p> <ul><li>Vouchers which suggest use for a specific adventure, but the value can be applied towards any adventure on site.</li><li>We secure the price of your chosen adventure for 18 months from the date of purchase, so even if the price increases during those 18 months, you won't have to pay any extra.</li><li>After 18 months, you can still use your voucher towards that adventure, but if the price has changed you will need to cover the difference.</li><li>You can use your adventure voucher credit towards any booking on the Adrenaline site.</li><li>Price guarantee date (18 months) and expiry date (60 months) shown on both the voucher and your order confirmation email.</li></ul> <p><strong>Flexi Vouchers</strong></p> <ul><li>$AUD value vouchers which can be used towards any adventure on site.</li><li>Expiry shown on both the voucher and your order confirmation email.</li></ul> <p><strong>Gift Cards</strong></p> <ul><li>Physical or digital e-gift cards purchased in-store or via third-party retailers</li><li>Valid to expiry printed on card.</li><li>Useable toward any adventure on site.</li><li>Gift Cards purchased on or after the 31st March 2018 have been extended to now have an expiry of 60 months from the date of purchase.</li></ul>",
      },
      {
        heading: 'How do I purchase an Adrenaline voucher?',
        content:
          "<p><strong>Adventure Vouchers</strong></p> <p>Any Adrenaline adventure can be purchased as an Adventure Voucher.</p> <ul><li>Find the adventure you would like to purchase at adrenaline.com.au</li><li>Select 'buy as a voucher'</li><li>Follow the prompts</li><li>Note: promo codes & offers may be used on purchasing an Adventure Voucher, however cannot be used when redeeming a voucher.</li></ul> <p><strong>Flexi Vouchers</strong></p> <p>Not sure what to get? Simply purchase a Flexi Voucher.</p> <ul><li>Select 'Buy a Voucher' in the Adrenaline navigation bar</li><li>Choose the amount</li><li>Select a design</li><li>Send via email or via Post</li></ul>",
      },
      {
        heading: "I haven't received my voucher",
        content:
          "<p><strong>Email</strong></p> <ul><li>Vouchers purchased with email delivery are sent instantly.</li><li>If you can't see your voucher, please check your junk & spam folders.</li><li>If an incorrect email address has been entered, or if you haven't received your voucher, please contact us to make the change.</li></ul> <p><strong>Post</strong></p> <ul><li>Vouchers sent by <strong>Standard Post</strong> take up to 5 business days to be received. Peak periods such as Christmas can take slightly longer. Tracking is not available for Standard Post.</li><li>Vouchers sent by <strong>Express Post</strong> can take up to 3 business days to be received. Peak periods such as Christmas can take slightly longer</li><li>Tracking details for Express Post are included with your order confirmation email.</li></ul>",
      },
      {
        heading: 'I bought my voucher through a third party, is it valid?',
        content:
          '<p>Adrenaline guarantees the validity of vouchers, e-gift cards, and gift cards purchased through authorised partners including Blackhawk Network Partners, Woolworths, Coles, Prezzee, Vii, True Rewards, Qantas, Redii, and MasterCard. If you are unsure of the authenticity of a reseller, please contact our team.</p><p>Adrenaline does not recommend the on-selling or purchasing of vouchers through unauthorised third-party sellers. If you purchase vouchers from another source, such as Viagogo, Facebook, eBay, Gumtree or any unauthorised reseller, you risk that the voucher is invalid. Adrenaline reserves the right to deactivate any vouchers that are re-sold via an unauthorised seller - or have been purchased through potentially fraudulent channels.</p><p>Unfortunately, Adrenaline cannot confirm the validity of any voucher to any individual except the original voucher purchaser. Purchasing a voucher from an unauthorised seller means that we cannot refund you if an experience is cancelled.</p>',
      },
    ],
  },
  {
    sectionHeading: 'Using Vouchers',
    content: [
      {
        heading: 'How do I redeem a voucher?',
        content:
          "To get started on your next adventure, just head to <a href=\"/en\">Adrenaline</a> and follow the below steps: <ol><li>Click 'Gift vouchers' in the top menu, then click 'Redeem a voucher'</li><li>Enter your voucher number (EG. ABC-123-XXX)</li><li>Follow the prompts to complete your order, or to add your voucher to your account.</li></ol>Please note that adventure vouchers over 18 months old will revert to Flexi Vouchers and display a dollar amount.",
      },
      {
        heading: 'Can I use my voucher for another adventure?',
        content:
          "<p>Absolutely! Adrenaline vouchers are fully flexible.</p><p>To redeem your voucher for another experience you just need to click 'Book Now' on your chosen experience, then enter your voucher number at the checkout. The voucher value will be deducted from your total.</p><p>If it costs more than your voucher credit, you can top up using a credit card, or one of our other accepted payment methods.</p>",
      },
      {
        heading: 'Why is my adventure voucher asking me to pay extra when I redeem?',
        content:
          '<p>Is your voucher older than 18 months?</p><p>The purchase price of your adventure will be held for 18 months from the date of purchase, so if you book and take your experience within the first 18 months (even if the price increased), you do not have to pay anything further.</p><p>After 18 months, you can still use your voucher towards that adventure, but if the price has changed you will need to cover the difference. Otherwise, you can use your adventure voucher credit towards any other booking on the Adrenaline site.</p>',
      },
      {
        heading: 'My voucher is about to expire, or has expired',
        content:
          '<p>Did you know, we\'ve extended all voucher and gift card validity to 5 years? Any Adrenaline Gift Voucher purchased on or after 1st December 2017 will have an expiry date of 60 months from the date of purchase. <a href="/en/redeem">Please click here to redeem</a>.</p><p>Gift cards purchased on or after the 31st March 2018 will also now have an expiry of 60 months.</p><p>If your voucher has expired due to extenuating circumstance, there may be a grace period available to you. Reach out to our Adventure Consultants for assistance.</p>',
      },
      {
        heading: "Why isn't my voucher working online?",
        content:
          '<ul><li>Check the voucher is for Adrenaline.com.au</li><li>Check you are entering the full voucher number including all hyphens</li><li>Check the expiry date on your voucher</li></ul><p>If you are still having trouble, chat with one of our legendary Adventure Consultants.</p>',
      },
    ],
  },
  {
    sectionHeading: 'Credit',
    content: [
      {
        heading: 'Why is my voucher a credit amount?',
        content:
          '<p>After 18 months, your vouchers are automatically converted into credit, as many of our onsite adventures change regularly. You can use your gift voucher credit towards any other adventure on the website.</p><p>The value of your voucher is valid for 5 years, if the price of the adventure displayed on the gift voucher increases after a period of 18 months you will need to pay the difference.</p>',
      },
      {
        heading: 'How do I use credit?',
        content:
          "<ol><li>Select the adventure you would like to do on the Adrenaline Website. Click 'Book Now'.</li><li>Complete the Booking page (make sure to select the number of participants you are booking)</li><li>Click proceed to checkout</li><li>Enter your voucher number in the 'Promos & Vouchers' section in the payment options.</li><li>To finalise your order, simply click on the 'Place Your Order'. Your request will be actioned normally.</li></ol><p>Please don't hesitate to contact us if you are having any trouble booking online.</p>",
      },
    ],
  },
  {
    sectionHeading: 'About Adrenaline',
    content: [
      {
        heading: 'Privacy Policy',
        content: '<a href="/en/privacy-policy">View our full privacy policy here</a>.',
      },
      {
        heading: 'Terms & Conditions',
        content: '<a href="/en/terms-and-conditions">View our full terms and conditions here</a>.',
      },
    ],
  },
]

export const listingRegions: WidgetRegionLink[] = [
  {
    name: 'Queensland',
    link: 'queensland',
    id: 0,
    regions: [
      {
        id: 0,
        name: 'Brisbane',
        link: 'brisbane',
      },
      {
        id: 0,
        name: 'Sunshine Coast',
        link: 'sunshine-coast',
      },
      {
        id: 0,
        name: 'Gold Coast',
        link: 'gold-coast',
      },
      {
        id: 0,
        name: 'Cairns',
        link: 'cairns',
      },
    ],
  },
  {
    name: 'Tasmania',
    link: 'tasmania',
    id: 0,
    regions: [
      {
        id: 0,
        name: 'Hobart',
        link: 'hobart',
      },
      {
        id: 0,
        name: 'Launceston',
        link: 'launceston',
      },
    ],
  },
  {
    name: 'New South Wales',
    link: 'new-south-wales',
    id: 0,
    regions: [
      {
        id: 0,
        name: 'Blue Mountains',
        link: 'blue-mountains',
      },
      {
        id: 0,
        name: 'Central Coast',
        link: 'central-coast',
      },
      {
        id: 0,
        name: 'South Coast',
        link: 'south-coast',
      },
      {
        id: 0,
        name: 'Wollongong',
        link: 'wollongong',
      },
      {
        id: 0,
        name: 'Sydney',
        link: 'sydney',
      },
      {
        id: 0,
        name: 'Hunter Valley',
        link: 'hunter-valley',
      },
      {
        id: 0,
        name: 'Port Stephens',
        link: 'port-stephens',
      },
    ],
  },
  {
    name: 'South Australia',
    link: 'south-australia',
    id: 0,
    regions: [
      {
        id: 0,
        name: 'Barossa Valley',
        link: 'barossa-valley',
      },
      {
        id: 0,
        name: 'Adelaide',
        link: 'adelaide',
      },
    ],
  },
  {
    name: 'Northern Territory',
    link: 'northern-territory',
    id: 0,
    regions: [
      {
        id: 0,
        name: 'Darwin',
        link: 'darwin',
      },
    ],
  },
  {
    name: 'Victoria',
    link: 'victoria',
    id: 0,
    regions: [
      {
        id: 0,
        name: 'Melbourne',
        link: 'melbourne',
      },
      {
        id: 0,
        name: 'Phillip Island',
        link: 'phillip-island',
      },
      {
        id: 0,
        name: 'Yarra Valley',
        link: 'yarra-valley',
      },
      {
        id: 0,
        name: 'Mornington Peninsula',
        link: 'mornington-peninsula',
      },
    ],
  },
  {
    name: 'Western Australia',
    link: 'western-australia',
    id: 0,
    regions: [
      {
        id: 0,
        name: 'Regional WA',
        link: 'wa-other',
      },
      {
        id: 0,
        name: 'Perth',
        link: 'perth',
      },
    ],
  },
]

export const listingCategories: WidgetCategoryLink[] = [
  {
    id: 0,
    name: 'V8 Hot Laps',
    link: 'v8-hot-laps',
  },
  {
    id: 0,
    name: 'V8 Supercar Drives',
    link: 'v8-experiences',
  },
  {
    id: 0,
    name: 'Rally Driving',
    link: 'rally-driving',
  },
  {
    id: 0,
    name: 'Quad Biking',
    link: 'quad-bikes',
  },
  {
    id: 0,
    name: 'Defensive and Stunt Driving',
    link: 'defensive-driving-and-stunt-driving',
  },
  {
    id: 0,
    name: 'Drifting',
    link: 'drifting',
  },
  {
    id: 0,
    name: 'F1 Style Driving',
    link: 'formula1-style-driving',
  },
  {
    id: 0,
    name: 'Supercar Drive Days',
    link: 'supercar-drive-days',
  },
  {
    id: 0,
    name: 'Off Road Racing',
    link: 'off-road-racing',
  },
  {
    id: 0,
    name: 'Formula Ford Experiences',
    link: 'formula-ford',
  },
  {
    id: 0,
    name: 'Extreme 4x4',
    link: 'extreme-4x4',
  },
  {
    id: 0,
    name: '4WD Training',
    link: '4wd-training',
  },
  {
    id: 0,
    name: 'Sports Car Rentals',
    link: 'sports-cars',
  },
  {
    id: 0,
    name: 'Radical Race Cars',
    link: 'radical-race-cars',
  },
]

// const today = new Date()
// const tomorrow = new Date().setDate(today.getDate() + 1)
// const todayDate = format(today, 'yyyy-MM-dd') as string
// const tomorrowDate = format(tomorrow, 'yyyy-MM-dd') as string
// export const productAvailabilityResponse: ProductAvailabilityResponse = {
//   status: 'success',
//   data: {
//     [todayDate]: {
//       sessions: [
//         {
//           id: 14099,
//           status: 'AVAILABLE',
//           options: [
//             {
//               id: 104187,
//               price: 54.95,
//               retailPrice: 54.95,
//               quantity: 1,
//             },
//           ],
//           totalPrice: 345,
//           totalRetailPrice: 233,
//           sessionName: 'General Admission',
//         },
//       ],
//     },
//     [tomorrowDate]: {
//       sessions: [
//         {
//           id: 14099,
//           status: 'AVAILABLE',
//           options: [
//             {
//               id: 104187,
//               price: 54.95,
//               retailPrice: 54.95,
//               quantity: 1,
//             },
//           ],
//           totalPrice: 345,
//           totalRetailPrice: 233,
//           sessionName: 'General Admission',
//         },
//         {
//           id: 14099,
//           status: 'UNAVAILABLE',
//           options: [
//             {
//               id: 104187,
//               price: 54.95,
//               retailPrice: 54.95,
//               quantity: 1,
//             },
//           ],
//           totalPrice: 345,
//           totalRetailPrice: 233,
//           sessionName: 'General Admission',
//         },
//       ],
//     },
//   },
// }

export const voucherCartItemResponse = {
  items: [
    {
      uuid: '5bba0427-cab2-4d41-a12a-f522d19f16ac',
      giftVoucher: {
        amount: 300,
        theme: 'ADR_FLYING',
        deliveryMethod: 'ELECTRONIC',
        recipient: {
          firstName: 'Test',
          lastName: 'User',
          email: 'test@testmail.com',
          emailDeliveryDate: '2024-07-23T05:07:24.575',
        },
        fieldMap: {
          TO: 'abc',
          FROM: 'abc',
          MESSAGE: 'abc abc abc',
        },
      },
      metadata: {},
      dateCreated: '2024-07-23T05:07:46.982369329Z',
      status: 'BOOKED',
      type: 'GIFT_VOUCHER',
      total: 300,
    },
    {
      uuid: '2bd5bd46-8278-4e2a-b485-f1ed185b6f4f',
      experienceVoucher: {
        productId: 2935,
        productName: 'Brighton Beach Boxes and Ricketts Point Helicopter Flight',
        productImage: 'https://cdn.experienceoz.com.au/assets/productCatalogueType/20724/image/203244.jpg',
        productSessionId: 7784,
        productSessionName: '9am Helicopter Flight',
        productOptionId: 27214,
        productOptionName: 'Passenger (2 years to 124kgs)',
        retailPrice: 0,
        price: 165,
        path: 'melbourne/ricketts-point-scenic-flight-10-minute-flight',
        theme: 'ADR_BUGGY',
        deliveryMethod: 'ELECTRONIC',
        recipient: {
          firstName: 'Test',
          lastName: 'User',
          email: 'test@testmail.com',
        },
        fieldMap: {
          TO: 'abc',
          MESSAGE: 'abc abc abc',
          FROM: 'abc',
        },
        allProductSessions: [
          {
            id: 7784,
            name: '9am Helicopter Flight',
            time: '09:00:00',
            options: [
              {
                id: 133099,
                name: 'Passenger (125kgs and above)',
                type: 'CUSTOM',
                hasVariablePricing: false,
                priceStrategy: 'RETAIL',
                retailPrice: 0,
                price: 247.5,
                markup: 0,
              },
              {
                id: 133098,
                type: 'INFANT_0_1',
                hasVariablePricing: false,
                priceStrategy: 'RETAIL',
                retailPrice: 0,
                price: 0,
                markup: 0,
              },
              {
                id: 27214,
                name: 'Passenger (2 years to 124kgs)',
                type: 'CUSTOM',
                hasVariablePricing: false,
                priceStrategy: 'RETAIL',
                retailPrice: 0,
                price: 165,
                markup: 0,
              },
            ],
          },
          {
            id: 27022,
            name: '4pm Helicopter Flight',
            time: '16:00:00',
            options: [
              {
                id: 78783,
                name: 'Passenger (2 years to 124kgs)',
                type: 'CUSTOM',
                hasVariablePricing: false,
                priceStrategy: 'RETAIL',
                retailPrice: 0,
                price: 165,
                markup: 0,
              },
              {
                id: 133105,
                name: 'Passenger (125kgs and above)',
                type: 'CUSTOM',
                hasVariablePricing: false,
                priceStrategy: 'RETAIL',
                retailPrice: 0,
                price: 247.5,
                markup: 0,
              },
              {
                id: 133104,
                type: 'INFANT_0_1',
                hasVariablePricing: false,
                priceStrategy: 'RETAIL',
                retailPrice: 0,
                price: 0,
                markup: 0,
              },
            ],
          },
          {
            id: 27020,
            name: '3:30pm Helicopter Flight',
            time: '15:30:00',
            options: [
              {
                id: 133102,
                type: 'INFANT_0_1',
                hasVariablePricing: false,
                priceStrategy: 'RETAIL',
                retailPrice: 0,
                price: 0,
                markup: 0,
              },
              {
                id: 78781,
                name: 'Passenger (2 years to 124kgs)',
                type: 'CUSTOM',
                hasVariablePricing: false,
                priceStrategy: 'RETAIL',
                retailPrice: 0,
                price: 165,
                markup: 0,
              },
              {
                id: 133103,
                name: 'Passenger (125kgs and above)',
                type: 'CUSTOM',
                hasVariablePricing: false,
                priceStrategy: 'RETAIL',
                retailPrice: 0,
                price: 247.5,
                markup: 0,
              },
            ],
          },
          {
            id: 27019,
            name: '10.30am Helicopter Flight',
            time: '10:30:00',
            options: [
              {
                id: 78780,
                name: 'Passenger (2 years to 124kgs)',
                type: 'CUSTOM',
                hasVariablePricing: false,
                priceStrategy: 'RETAIL',
                retailPrice: 0,
                price: 165,
                markup: 0,
              },
              {
                id: 133100,
                type: 'INFANT_0_1',
                hasVariablePricing: false,
                priceStrategy: 'RETAIL',
                retailPrice: 0,
                price: 0,
                markup: 0,
              },
              {
                id: 133101,
                name: 'Passenger (125kgs and above)',
                type: 'CUSTOM',
                hasVariablePricing: false,
                priceStrategy: 'RETAIL',
                retailPrice: 0,
                price: 247.5,
                markup: 0,
              },
            ],
          },
        ],
        supplier: {
          id: 3051,
          address: 'xnnvjhrrbw,  unxhcm,  5414',
          name: 'Professional Helicopter Services Melbourne',
        },
      },
      metadata: {},
      dateCreated: '2024-07-24T22:33:39.564153666Z',
      status: 'BOOKED',
      type: 'EXPERIENCE_VOUCHER',
      total: 165,
    },
  ],
}
